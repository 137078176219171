export default {
    data: function(){
        return {
            visible: false,
            state: null,
            defaultValue: null
        }
    },
    methods: {
        open: function(state, defaultValue){
            this.state = state;
            this.defaultValue = defaultValue;
            this.visible = true;
        },
        close: function(){
            this.state = null;
            this.visible = false;
        }
    }
}