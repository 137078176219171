// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/playing/monitor.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-item .list-item-icon[data-v-af47f23a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:96px 46px;height:46px}.ds-dialog-fog[data-v-af47f23a]{z-index:3;position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,.75);display:flex;align-items:center;justify-content:center;overflow-y:hidden}.ds-minidialog[data-v-af47f23a]{max-width:480px;width:100%;max-height:90%;overflow:hidden;background:#efefef;border:1px solid #080808;box-sizing:border-box;box-shadow:0 0 8px rgba(0,0,0,.3);z-index:2;display:flex;flex-direction:column}.dialog-header[data-v-af47f23a]{background:#555;color:#fff;margin:0;padding:10px;font-size:1.3em;font-weight:700}.ds-minidialog .listview[data-v-af47f23a]{max-height:50vh;background-color:#fff;padding:4px;border:1px solid #dedede;display:block;overflow-y:auto}.dialog-footer[data-v-af47f23a]{margin:0 5px;padding:10px;text-align:right;border-top:1px solid #ccc}.btn[data-v-af47f23a]{font:400 10pt Golos Text,Open Sans,Segoe UI,Liberation Sans,sans-serif;background:#888;color:#fff;border:0;border-radius:3px;padding:5px 10px;vertical-align:bottom;min-width:120px;cursor:pointer;display:inline-block;text-decoration:none}.list-item[data-v-af47f23a]{display:flex;align-items:center;padding:5px 10px 5px 5px;box-sizing:border-box;border-bottom:1px solid #ddd}.list-item-icon[data-v-af47f23a]{width:96px;align-self:stretch;background-position:50%;background-repeat:no-repeat;background-size:auto 48px}.list-item-caption[data-v-af47f23a]{flex-grow:1;padding:10px;min-height:56px;cursor:pointer}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
