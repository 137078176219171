// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/playlist/monitor.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:414px){.item td .device[data-v-27141b38],.item td .device .number[data-v-27141b38]{font-size:12px}}.main_content[data-v-27141b38]{width:100%;overflow-y:scroll}.main_content .content[data-v-27141b38]{display:flex;align-items:center;width:100%;padding:3px}.delete[data-v-27141b38]{background-color:#e73c3c}.btn-green[data-v-27141b38]{margin-top:10px;margin-left:10px;background-color:#3fc23f;border-radius:6px;font-size:16px;width:140px}.btn-move[data-v-27141b38]{background-color:#4391d2}.btn-info[data-v-27141b38]{background-color:#49b4e2}.btn-edit[data-v-27141b38]{background-color:#ffc61c}.btn-arrow[data-v-27141b38]{background-color:#60db27}.btn-block[data-v-27141b38]{background-color:#ff911c}.list-item[data-v-27141b38]{display:flex;align-items:center;padding:5px 10px 5px 5px;box-sizing:border-box;border-bottom:1px solid #ddd}.list-item-caption[data-v-27141b38]{flex-grow:1;padding:10px;min-height:56px}.list-item-buttons>button[data-v-27141b38]{margin-left:5px;padding:5px 15px;background:#eee;color:#111;padding:6px;border:0;border-radius:4px;font-family:inherit}.list-item-buttons>button>img[data-v-27141b38]{vertical-align:middle}.list-item-buttons>button[data-v-27141b38]:hover{background:#ccc}.list-item-icon[data-v-27141b38]{width:96px;align-self:stretch;background-position:50%;background-repeat:no-repeat;background-size:auto 48px}.list-item-icon-player[data-v-27141b38]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.player-status-offline[data-v-27141b38]{opacity:.5}.player-status-deadline[data-v-27141b38]{background-color:#fcb}.list-group-header[data-v-27141b38]{background-color:#eee}.list-group-header>.list-item-caption[data-v-27141b38]{min-height:auto;font-weight:700}.list-group-header>.list-item-icon[data-v-27141b38]{display:none}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
