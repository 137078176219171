<template>
    <div class="time_container">
        <div class="select_time">
        <span>Выполнять</span>
        <select v-model="scheduleType">
            <option value="default" v-if="!disableDefaultTask || isDefaultTask">Как основную задачу</option>
            <option value="oneshot" v-if="allowOneShot">По расписанию</option>
            <option value="interval">В течении периода</option>
        </select>
        </div>
        <div v-if="scheduleType != 'default'" class="select_time">
        <span>Повторять</span>
        <select v-model="repeatMode">
            <option value="daily">Не повторять</option>
            <option value="hourly">Каждый час</option>
        </select>
        </div>
        <div v-if="scheduleType != 'default'" class="select_time">
        <span>Начало в</span>
        <input v-if="repeatMode == 'daily'" type="time" v-model="startTime" :class="{'is-invalid': startTimeIsInvalid}" />
        <input v-else type="number" v-model="startTime" :class="{'is-invalid': startTimeIsInvalid}" />
        </div>
        <div v-if="scheduleType == 'interval'" class="select_time">
        <span>Завершение в</span>
        <input v-if="repeatMode == 'daily'" type="time" v-model="endTime" :class="{'is-invalid': endTimeIsInvalid}" />
        <input v-else type="number" v-model="endTime" :class="{'is-invalid': endTimeIsInvalid}" />
        </div>
    </div>
</template>

<script>
import { DailyTime, DefaultSchedule, HourlyTime, PlayUntilCompleted, PlayUntilTime, Task } from '@/api/ScheduleModel'

export default {
    props: {
        task: Object,
        disableDefaultTask: Boolean,
        allowOneShot: Boolean
    },
    data: function(){
        return {
            startTimeIsInvalid: false,
            endTimeIsInvalid: false
        }
    },
    computed: {
        isDefaultTask: function(){
            return this.task.schedule instanceof DefaultSchedule;
        },
        scheduleType: {
            get() {
                if(!(this.task instanceof Task)) return null;
                if(this.task.schedule instanceof DefaultSchedule) {
                    return 'default';
                } else if(this.task.schedule instanceof PlayUntilCompleted) {
                    return 'oneshot';
                } else if(this.task.schedule instanceof PlayUntilTime) {
                    return 'interval';
                }
                return 
            },
            set(newValue) {
                let hourly = !(this.task.schedule instanceof DefaultSchedule) && (this.task.schedule.start instanceof HourlyTime);
                let defaultStart = this.task.schedule.start === undefined ? new DailyTime(0, 0) : this.task.schedule.start;
                let defaultEnd = hourly ? new HourlyTime(59) : new DailyTime(23, 59)
                switch(newValue){
                    case 'default':
                        this.task.schedule = new DefaultSchedule();
                        break;
                    case 'oneshot':
                        this.task.schedule = new PlayUntilCompleted(defaultStart);
                        break;
                    case 'interval':
                        this.task.schedule = new PlayUntilTime(defaultStart, defaultEnd);
                        break;
                }
            }
        },
        repeatMode: {
            get(){
                if(!(this.task instanceof Task)) return null;
                if(this.task.schedule instanceof DefaultSchedule) return null;
                if(this.task.schedule.start instanceof HourlyTime){
                    return 'hourly';
                } else {
                    return 'daily';
                }
            },
            set(newValue) {
                if(this.task.schedule instanceof DefaultSchedule) return null;
                let hasEnd = this.task.schedule instanceof PlayUntilTime;
                switch(newValue) {
                    case 'hourly':
                        this.task.schedule.start = new HourlyTime(0);
                        if(hasEnd) this.task.schedule.end = new HourlyTime(59);
                        break;
                    case 'daily':
                        this.task.schedule.start = new DailyTime(0, 0);
                        if(hasEnd) this.task.schedule.end = new DailyTime(23, 59);
                        break;
                }
            }
        },
        startTime: {
            get() {
                if(!(this.task instanceof Task)) return null;
                if(this.task.schedule instanceof DefaultSchedule) return null;
                return this.task.schedule.start.toString()
            },
            set(newValue) {
                if(this.task.schedule instanceof DefaultSchedule) return null;
                if(this.task.schedule.start instanceof HourlyTime){
                    let minutes = parseInt(newValue)
                    if((minutes > 59) || (minutes < 0)){
                        this.startTimeIsInvalid = true;
                        return;
                    }
                    this.startTimeIsInvalid = false;
                    this.task.schedule.start = new HourlyTime(minutes);
                } else if(this.task.schedule.start instanceof DailyTime){
                    let [hour, minute] = newValue.split(':').map((it) => parseInt(it));
                    if((hour > 23) || (hour < 0) || (minute > 59) || (minute < 0)){
                        this.startTimeIsInvalid = true;
                        return;
                    }
                    this.startTimeIsInvalid = false;
                    this.task.schedule.start = new DailyTime(hour, minute);
                }
            }
        },
        endTime: {
            get() {
                if(!(this.task instanceof Task)) return null;
                if(!(this.task.schedule instanceof PlayUntilTime)) return null;
                return this.task.schedule.end.toString()
            },
            set(newValue) {
                if(!(this.task.schedule instanceof PlayUntilTime)) return null;
                if(this.task.schedule.end instanceof HourlyTime){
                    let minutes = parseInt(newValue)
                    if((minutes > 59) || (minutes < 0)){
                        this.endTimeIsInvalid = true;
                        return;
                    }
                    this.endTimeIsInvalid = false;
                    this.task.schedule.end = new HourlyTime(minutes);
                } else if(this.task.schedule.end instanceof DailyTime){
                    let [hour, minute] = newValue.split(':').map((it) => parseInt(it));
                    if((hour > 23) || (hour < 0) || (minute > 59) || (minute < 0)){
                        this.endTimeIsInvalid = true;
                        return;
                    }
                    this.endTimeIsInvalid = false;
                    this.task.schedule.end = new DailyTime(hour, minute);
                }
            }
        },
    }
}
</script>

<style scoped>
.select_time input, .select_time select {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    height: 40px;
    min-width: 165px;
}
.time_container {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
}
.select_time {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #141414;
    padding: 10px;
    width: 26%;
}
.select_time select {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    height: 40px;
}
.select_time span {
    font-size: 10pt;
    font-weight: bold;
    padding: 5px;
}
.is-invalid {
    border: 2px solid #F52;
}
</style>