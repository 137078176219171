<template>
    <div class="layout">
        <div class='layout-main'>
            <Sidebar :isedit="editedPlaylist"/>
            <div class="layout-content">
                <div class='layout-content-wrapper'>
                    <HintModal v-if="openHint" @closemodal="openHint = false"/>
                    <Hint @openhint="openHint = true" />
                    <router-view :isAuthorized="authorizationManager.isAuthorized"/>
                </div>
            </div>
        </div>
		<WarningModal :isActive="messagePopup.active" :text="messagePopup.message"></WarningModal>
    </div>
</template>
<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Hint from './components/Hint/Hint.vue'
import HintModal from './components/Hint/HintModal.vue'
import { PlayersAPI, GroupsAPI, SchedulesAPI, FilesAPI, AuthorizationAPI } from '@/api/wrappers'
import { getCookie, setCookie } from '@/api/func'
import WarningModal from './components/WarningModal.vue'

class PopupController {
	constructor() {
		this.active = false;
		this.message = null;
		this.timeout = null;
	}

	show(message) {
		if(this.timeout !== null) clearTimeout(this.timeout)
		this.message = message;
		this.active = true;
		this.timeout = setTimeout(() => {
			this.timeout = null;
			this.hide();
		}, 2000);
	}

	hide() {
		this.message = null;
		this.active = false;
	}

}

class AuthorizationManager {
	constructor() {
		this.isAuthorized = false;
		this.api = new AuthorizationAPI();
	}

	async signIn(username, password) {
		try {
			let session = await this.api.login(username, password);
			setCookie('session_id', session.id, 7);
			setCookie('user', username, 7);
			this.isAuthorized = true;
		} catch(e) {
			throw Error('Неправильный логин или пароль');
		}
	}

	async signOut() {

	}

	setLoggedIn() {
		this.isAuthorized = true;
	}

	setLoggedOut() {
		this.isAuthorized = false;
	}
}

export default {
  name: 'App',
  components: {
    Sidebar,
    Hint,
    HintModal,
    WarningModal
},
  provide() {
	return {
      playersAPI: new PlayersAPI(),
      groupsAPI: new GroupsAPI(),
      schedulesAPI: new SchedulesAPI(),
      filesAPI: new FilesAPI(),
      authorizationAPI: this.authorizationAPI,
	  messagePopup: this.messagePopup,
	  authorizationManager: this.authorizationManager
	}
  },
  data: () => ({
    openHint: false,
    editedPlaylist: false,
	authorizationAPI: new AuthorizationAPI(),
	messagePopup: new PopupController(),
	authorizationManager: new AuthorizationManager()
  }),
  mounted(){
	const players = new PlayersAPI();
	players.fetch().then(() => {
		this.authorizationManager.setLoggedIn();
	}).catch((e) => {
	})
  },
  methods: {
    savePlaylist(isEdit) {
      this.editedPlaylist = isEdit
      console.log('savePlaylist', isEdit)
    },
  }
}
</script>
<style>
:root {
  --hint-size: 40px;
  --hint-white-size: 32px;
  --width-hint: 90%;
}
@media (max-width: 414px) {
  .container {
    flex-direction: column;
  }
}
#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html, body {
	height: 100%;
	width: 100%;
}

.btn {
  font: 400 10pt "Golos Text", "Open Sans", "Segoe UI", "Liberation Sans", sans-serif;
  background: #888;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 5px 10px;
  vertical-align: bottom;
  min-width: 120px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin: 0 2px;
}
.btn:hover {
  background: #737373;
}
.btn:first-child {
  margin-right: 5px;
}
.cursor {
  cursor: pointer;
}
.btn-primary.active {
	background: #888;
}
.btn-primary, .btn-info.active {
	background-color: #6c8;
	transition: .3s ease-in-out all;
	margin-right: 5px;
}
.btn-primary:hover {
	background-color: rgb(85, 187, 119);
}

/** legacy ds layout styles */
.layout {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.layout-bottom {
	background-color: #C64;
	color: #FFF;
	padding: 16px;
	/* background-image: url('/static/mmvs-mono.png'); */
	background-position: 64px center;
	background-repeat: no-repeat;
	background-size: auto 80%;
}
.layout-main {
	flex: 1 1 100%;
	display: flex;
	flex-direction: row;
	min-height: 50%;
}
.layout-sidebar {
	box-sizing: border-box;
	background-color: #30363c;
	color: #FFF;
	flex-basis: 300px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
}
.sidebar-header {
	font-weight: bold;
    text-transform: uppercase;
    padding: 20px;
    display: flex;
	align-items: center;
    background: #202830;
    height: 72px;
    flex-shrink: 0;
    flex-grow: 0;
    box-sizing: border-box;
}
.sidebar-menu {
	padding: 5px;
	flex-grow: 1;
}
.sidebar-menu > .sidebar-item {
	color: #CCC;
    padding: 15px;
    display: block;
    border-radius: 5px;
    display: flex;
	align-items: center;
    cursor: pointer;
	text-decoration: none;
}
.sidebar-menu > .sidebar-item > img {
	margin-right: 20px;
    opacity: .4;
}
.sidebar-menu > .sidebar-item.active > img {
    opacity: 1;
}
.sidebar-menu > .sidebar-item:hover {
	color: #FFF;
    background: #20262c;
    text-decoration: none;
}
.sidebar-menu > .sidebar-item.active {
	font-weight: bold;
	color: #FFF;
}
.sidebar-footer {
	padding: 16px;
}
.sidebar-login-block {
	padding: 8px 16px;
}
.layout-content {
	flex-grow: 1;
	height: 100%;
	padding-top: 72px;
	background-color: #DDD;
	overflow: hidden;
}
.layout-content-wrapper {
	height: 100%;
	background-color: #FFF;
	overflow: auto;
}
.layout-sidebar-collapsed {
	flex-basis: auto;
}
.layout-sidebar-collapsed > .sidebar-menu,
.layout-sidebar-collapsed > .sidebar-login-block,
.layout-sidebar-collapsed > .sidebar-footer,
.layout-sidebar-collapsed > .sidebar-header > b {
	display: none;
}

#sidebarSwitch {
	font-size: 18pt;
	vertical-align: middle;
	margin-top: -5px;
	margin-left: auto;
	border: 0;
	background: transparent;
	cursor: pointer;
	color: #FFF;
}
/** Mobile devices **/
@media only screen and (max-device-width: 640px) {
	.layout-main {
		flex-direction: column;
	}
	.layout-content {
		padding-top: 0;
	}
	.sidebar-menu, .sidebar-login-block, .sidebar-footer {
		display: none;
	}
	.layout-sidebar {
		flex-basis: auto;
	}
	.layout-sidebar-collapsed > .sidebar-menu, .layout-sidebar-collapsed > .sidebar-login-block, .layout-sidebar-collapsed > .sidebar-footer {
		display: block;
	}
	.layout-sidebar-collapsed > .sidebar-header > b {
		display: block;
	}
}
/** legacy ds listview */
.list-item {
	display: flex;
	align-items: center;
	padding: 5px 10px 5px 5px;
	box-sizing: border-box;
	border-bottom: 1px solid #DDD;
}
.list-item-caption {
	flex-grow: 1;
	padding: 10px;
	min-height: 56px;
	text-overflow: ellipsis;
	overflow-x: hidden;
}
.list-item-buttons {
	white-space: nowrap;
}
.list-item-buttons > button,
.list-item-buttons > a {
	margin-left: 5px;
	padding: 5px 15px;
	background: #EEE;
	color: #111;
	padding: 6px;
	border: 0;
	border-radius: 4px;
	font-family: inherit;
}
.list-item-buttons > button > img,
.list-item-buttons > a > img {
	vertical-align: middle;
	width: 24px;
	height: 24px;
}
.list-item-buttons > button:hover,
.list-item-buttons > a:hover {
	background: #CCC;
}
.list-item-icon {
	width: 96px;
	align-self: stretch;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto 48px;
	flex-shrink: 0;
}
</style>
