export class APIError {
    constructor(response) {
        this.response = response;
    }
}
export class AuthorizationError extends APIError {

}

function parseTime(time) {
    let [hour, minute] = time.split(':');
    return { hour: hour, minute: minute }
}

class APIWrapper {
    async get(uri, options = {}) {
        let returnAll = options.returnAll || false;
        const res = await fetch(uri, {
            method: 'GET',
        })
        const resp = await res.json()
        if(res.status == 403){
            throw new AuthorizationError(resp);
        }
        if(resp.status == 'ok'){
            return returnAll ? resp : resp.result;
        } else {
            throw new APIError(resp);
        }
    }
    async post(uri, data, options = {}) {
        let returnAll = options.returnAll || false;
        const res = await fetch(uri, {
            method: 'POST',
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const resp = await res.json()
        if(res.status == 403){
            throw new AuthorizationError(resp);
        }
        if(resp.status == 'ok'){
            return returnAll ? resp : resp.result;
        } else {
            throw new APIError(resp);
        }
    }
}

export class AuthorizationAPI extends APIWrapper {
    async login(username, password) {
        return this.post('/api/login', { user: username, password })
    }

    async logout(sessionId) {
        return this.post('/api/logout', { session: sessionId })
    }
}

export class PlayersAPI extends APIWrapper {
    async fetch() {
        return this.get('/api/players/list');
    }

    async status(player) {
        return this.get('/api/players/' + player + '/status', { returnAll: true }).then(it => it.data);
    }

    async assign(player, schedule) {
        return this.post('/api/players/assign', { player, group: schedule }) // NOTE: forgive this atavism
    }

    async assignMany(players, schedule) {
        return this.post('/api/players/assign_multiple', { players, schedule })
    }

    async reset(player) {
        return this.post('/api/players/reset', { player })
    }

    async rename(player, name) {
        return this.post('/api/players/rename', { player, name })
    }

    async setTimezone(player, tzOffset) {
        return this.post('/api/players/assign', { player, tzOffset })
    }

    async updateHeadline(player, text) {
        if(Array.isArray(player)) {
            return this.post('/api/subs/multicast', { players: player, content: text })
        } else {
            return this.post('/api/subs/update', { player, content: text })
        }
    }

    async broadcastHeadline(text) {
        return this.post('/api/subs/broadcast', { content: text })
    }

    async getHeadlines() {
        return this.get('/api/subs/list');
    }

    async getParameters(player) {
        return this.post('/api/subcontrols/get_state', { id: player })
    }

    async setVolume(player, volume) {
        return this.post('/api/subcontrols/set_volume', { id: player, volume })
    }

    async setParameter(player, param, value) {
        return this.post('/api/subcontrols/set_param', { id: player, param, value });
    }

    async userCommand(player, password, command) {
        return this.post('/api/players/usercommand', { player, password, command })
    }

    async adminCommand(player, command) {
        return this.post('/api/players/remotecmd', { player, command })
    }

    async adminCommandHistory(player) {
        return this.post('/api/players/remotecmd/list', { player })
    }

    async adminCommandDelete(player, requestId) {
        return this.post('/api/players/remotecmd/delete', { player, requestId })
    }

    async adminCommandClear(player) {
        return this.post('/api/players/remotecmd/clear', { player })
    }

}

export class GroupsAPI extends APIWrapper {
    async fetch() {
        return this.get('/api/groups/list')
    }

    async create(name) {
        return this.post('/api/groups/create', { name: name })
    }

    async createWithPlayers(name, players) {
        return this.post('/api/groups/create', { name: name, players })
    }

    async assign(groupId, scheduleId) {
        return this.post('/api/groups/assign', { group: groupId, schedule: scheduleId })
    }

    async include(groupId, playerId) {
        return this.post('/api/groups/include', { group: groupId, player: playerId })
    }

    async exclude(groupId, playerId) {
        return this.post('/api/groups/exclude', { group: groupId, player: playerId })
    }

    async remove(groupId) {
        return this.post('/api/groups/remove', { group: groupId })
    }
}

export class SchedulesAPI extends APIWrapper {
    async fetch() {
        return this.get('/api/schedules/list')
    }

    async read(schedule) {
        return this.post('/api/schedules/read', { schedule }, { returnAll: true }).then(it => it.data);
    }

    async create(name) {
        return this.post('/api/schedules/create', { name }, { returnAll: true }).then(it => it.data);
    }

    async copy(schedule, copyName) {
        return this.post('/api/schedules/copy', { schedule, copy_name: copyName })
    }

    async rename(schedule, name) {
        return this.post('/api/schedules/rename', { schedule, name })
    }

    async oldSaveMethod(schedule, config) {
        return this.post('/api/schedules/write', { schedule, config })
    }

    async save(schedule, description) {
        return this.post('/api/schedules/save', { id: schedule, description })
    }

    async remove(schedule) {
        return this.post('/api/schedules/remove', { schedule })
    }

    async addSleepTask(schedule, startTime, endTime) {
        return this.post('/api/schedules/add_sleep', { schedule, time: [parseTime(startTime), parseTime(endTime)] })
    }
}

export class FilesAPI extends APIWrapper {
    async fetch() {
        return this.get('/api/files/list_all')
    }

    async fetchFolder(folder) {
        if(folder) {
            return this.get('/api/files/list?folder=' + folder)
        } else {
            return this.get('/api/files/list')
        }
    }

    async upload(files, folder = null) {
        let fd = new FormData();
        if(folder !== null) {
            fd.append('folder', folder);
        }
        files.forEach(function(f){
            fd.append('upfile', f);
        });
        let res = await fetch("/api/files/upload", {
            method: 'POST',
            body: fd
          })
        const resp = await res.json()
        if(res.status == 403){
            throw new AuthorizationError(resp);
        }
        if(resp.status == 'ok'){
            return resp;
        } else {
            throw new APIError(resp);
        }
    }

    async createFolder(name) {
        return this.post('/api/files/makedir', { name, parent: null })
    }

    async remove(fileId) {
        return this.post('/api/files/del', { filename: fileId })
    }

    async removeFolder(id) {
        return this.post('/api/files/removedir', { id })
    }

    async removeMultiple(files) {
        return this.post('/api/files/delete_multiple', { files })
    }

    async rename(id, name) {
        return this.post('/api/files/rename', { id, name })
    }

    async renameFolder(id, name) {
        return this.post('/api/files/renamedir', { id, name })
    }

    async moveFiles(folder, files) {
        return this.post('/api/files/move_multiple', { folder, files })
    }

    async convertingFiles() {
        return this.get('/api/files/status', { returnAll: true }).then(it => it.data);
    }
}