<template>
	<div class="main_content">
    <Header />
    <WarningModal :isActive="openWarning" :text="warningText"/>
    <LoginModal v-if="openLogin" @savemodal="authUser" @closemodal="openLogin = false"/>
		<div class="content">
      <div class="header">Доступ ограничен</div>
      <div class="login" @click="openLogin = true">Выполните вход</div>
		</div>
	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import LoginModal from '@/components/LoginModal.vue'
import WarningModal from '@/components/WarningModal.vue'

export default {
  name: 'Login',
  components: {
    Header,
    LoginModal,
    WarningModal,
  },
  inject: ['authorizationAPI', 'authorizationManager', 'messagePopup'],
  data: () => ({
    openLogin: false,
    openWarning: false,
    warningText: "",
  }),
  beforeRouteLeave(to, from, next) {
    if(this.$attrs.isAuthorized) {
      next();
    }
  },
  mounted() {
    if(this.$attrs.isAuthorized) {
      this.$router.push('/device');
      return;
    }
    if(this.$route.query.login) {
      this.openLogin = true
    }
  },
  methods: {
    async authUser(credentials) {
      try {
        await this.authorizationManager.signIn(credentials.user, credentials.pass);
      } catch(e) {
        this.messagePopup.show('Неправильный логин или пароль')
      }
    }
  },
  watch: {
    '$attrs.isAuthorized': function() {
      if(this.$attrs.isAuthorized) {
        this.$router.push('/device');
      }
    },
    page() {
      this.$router.replace({query: {}})
      this.openLogin = true
    }
  },
computed: {
  page () {
    return this.$route.query.login
  }
}
}
</script>
<style scoped>
  .content {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
  }
  .header {
    font-size: 1.6em;
    margin-bottom: 1em;
  }
  .login {
    color: #28d;
    cursor: pointer;
    text-decoration: underline;
  }
</style>
