<template>
	<div class="main_content">
    <InputModal
      ref='addGroupModal'
      title="Добавить новую группу"
      @submit="createGroup" />
    <SelectScheduleModal
      ref='selectScheduleModal'
      :schedules='schedules'
      @select-schedule="saveSchedule" />
    <InputModal
      ref='renamePlayerModal'
      title="Сменить имя"
      buttonName="Сохранить"
      @submit="changeName" />
    <ScreenshotModal
      ref='playerScreenshotModal'
      title="Снимок экрана" />
    <StatusModal
      ref='playerStatusModal'
      :headlines="headlinesById"
      :schedules="schedulesById"
      :files="filesById"
      @headline-updated="fetchHeadlines"
      @select-schedule="selectSchedule" />
    <SelectPlayerModal
      ref='addPlayerModal'
      :players="players"
      :groups="groups"
      @select-player="includePlayer" />
    <ConfirmModal
      ref='deleteGroupModal'
      title="Удаление группы"
      text="Вы действительно хотите удалить группу"
      okText="Удалить"
      type="delete"
      @confirm="delGroup" />
    <PreviewModal
      ref='previewPlayerModal'
      title='Предпросмотр'
      :files="filesById" />

    <Header>
      <button @click="openModal('addGroupModal', null)" class="btn btn-primary">Создать группу</button>
    </Header>
		<div class="content">
			<table cellpadding="5" width="100%">
        <tbody>
          <div v-for="group in groupedPlayers" :key="group.id">
            <div class="list-item list-group-header" @click="toggleGroup(group.id)">
              <div class='list-item-caption'>
                {{ group.id === 0 ? 'Все устройства' : group.name }}
              </div>
              <div class="list-item-buttons">
                <!-- <button @click.stop="selectSchedule(group.players, 'playlist')" type="button" title="Установить плейлист">
                  <img src="@/assets/imgs/device/schedule.svg">
                </button> -->
                <button @click.stop="addPlaylist(group)" v-if="group.id !== 0" type="button" title="Добавить в группу">
                  <img src="@/assets/imgs/device/add.svg">
                </button>
                <button v-if="group.id !== 0" @click.stop="openModal('deleteGroupModal', group)" type="button" title="Удалить группу">
                  <img src="@/assets/imgs/playlist/delete.svg">
                </button>
                <button type="button" title="Развернуть" v-if="collapsedGroups.has(group.id)">
                  <img src="@/assets/imgs/device/down.svg">
                </button>
                <button type="button" title="Свернуть" v-else>
                  <img src="@/assets/imgs/device/up.svg">
                </button>
              </div>
            </div>
            <template v-if="!collapsedGroups.has(group.id)">
              <div
                class="list-item"
                v-for="player in group.players"
                @click="openModal('playerStatusModal', player)"
                :key="player.id"
                :class="{'player-status-offline': player.status == 'offline', 'player-status-deadline': player.status == 'deadline'}"
              >
                <div class='list-item-icon list-item-icon-player'></div>
                <div class="list-item-caption">
                    <div><b>{{player.name}}</b></div>
                    <div>{{player.status === 'live' ? 'Online' : player.status}}</div>
                    <div>
                      <span>
                        {{player.scheduleName}} 
                      </span>
                      <span v-if="player.nextScheduleName">
                        {{` (Устанавливается ${player.nextScheduleName})`}}
                      </span>
                      <span v-if="player.lastError" class="error">
                        {{` Не удалось применить расписание ${player.lastError.request.scheduleName} (${player.lastError.error})`}}
                      </span>
                    </div>
                </div>
                <div class="list-item-buttons">
                  <button
                    @click="openModal('playerStatusModal', player)"
                    title="Статус плеера"
                  ><img src="/static/icons/info.svg"></button>
                  <button
                    @click.stop="openModal('previewPlayerModal', player)"
                    title="Предпросмотр"
                  ><img src="/static/icons/preview.svg"></button>
                  <button
                    @click.stop="openModal('playerScreenshotModal', player)"
                    title="Снимок экрана"
                  ><img src="/static/icons/camera.svg"></button>
                  <button
                    @click.stop="openModal('selectScheduleModal', player)"
                    title="Установить плейлист"
                  ><img src="/static/icons/schedule.svg"></button>
                  <button
                    @click.stop="openModal('renamePlayerModal', player, player.name)"
                    title="Переименовать"
                  ><img src="/static/icons/rename.svg"></button>
                  <button
                    @click.stop="excludePlayer(group.id, player.id)"
                    v-if="group.id !== 0"
                    title="Убрать из группы"
                  ><img src="/static/icons/remove.svg"></button>
                </div>
              </div>
            </template>
          </div>
        </tbody>
		  </table>
		</div>
	</div>
</template>
<script>
import Header from '@/components/Header.vue'
import SelectScheduleModal from '@/components/Devices/SelectScheduleModal.vue'
import StatusModal from '@/components/Devices/StatusModal.vue'
import SelectPlayerModal from '@/components/Devices/SelectPlayerModal.vue'
import InputModal from '@/components/InputModal.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import ScreenshotModal from '@/components/Devices/ScreenshotModal.vue'
import { APIError } from '@/api/wrappers'
import PreviewModal from '../components/Devices/PreviewModal.vue'
import ViewWithModalsMixin from '@/mixins/ViewWithModalsMixin'

export default {
  components: {
    Header,
    InputModal,
    ConfirmModal,
    SelectScheduleModal,
    SelectPlayerModal,
    ScreenshotModal,
    StatusModal,
    PreviewModal
  },
  name: 'ActiveLists',
  mixins: [ViewWithModalsMixin],
  inject: ['playersAPI', 'groupsAPI', 'schedulesAPI', 'filesAPI', 'messagePopup'],
  data: () => ({
    text_warning: null,

    players: [],
    groups: [],
    schedules: [],
    headlines: [],
    files: [],
    collapsedGroups: new Set(),
    scheduledUpdate: null,

    modals: {
      groupCreate: false,
      groupRemove: false,
      groupInclude: false,
      playerSetSchedule: false,
      playerStatus: false,
      playerRename: false,
      playerScreenshot: false
    },
    openSchedule: false,
    clickedItem: {},
    openWarning: false,
  }),
  computed: {
    groupedPlayers: function(){
      let playersById = this.players.reduce((a, player) => {
        a[player.id] = player;
        return a;
      }, {});
      return this.groups.map(function(group){
        return {
          id: group.id,
          name: group.name,
          players: group.players.map(function(player) {
            return playersById[player.id]
          }).sort(function(a, b){
              if(a.status == b.status)
                  return a.name.localeCompare(b.name);
              if(b.status == 'live'){
                  return 1;
              } else if(a.status == 'live'){
                  return -1;
              } else if(b.status == 'deadline'){
                  return 1;
              } else if(a.status == 'deadline'){
                  return -1;
              }
          })
        }
      })
    },
    availablePlayers: function(){
      if(this.clickedItem == null) return this.players;
      let currentGroupId = this.clickedItem.id;
      let group = this.groups.filter(it => it.id == currentGroupId)[0];
      let groupPlayers = group.players.map(it => it.id);
      return this.players.filter((player) => {
        return !groupPlayers.includes(player.id);
      })
    },
    headlinesById: function(){
      let result = {};
      this.headlines.forEach(function(headline) {
        result[headline.id] = headline;
      })
      return result;
    },
    schedulesById: function(){
      let result = {};
      this.schedules.forEach(function(schedule) {
        result[schedule.id] = schedule;
      })
      return result;
    },
    filesById: function(){
      let result = {};
      this.files.forEach(function(file) {
        result[file.id] = file;
      })
      return result;
    },
  },
  mounted() {
    this.collapsedGroups.add(0);
    this.fetchAll();
    this.fetchSchedules();
    this.fetchFiles();
    this.fetchHeadlines();
  },
  unmounted() {
    clearTimeout(this.scheduledUpdate)
  },
  methods: {
    async fetchAll(force) {
      if(force && this.scheduledUpdate != null) {
        clearTimeout(this.scheduledUpdate);
      }
      await this.fetchPlayers();
      await this.fetchGroups();
      this.scheduledUpdate = setTimeout(this.fetchAll.bind(this), 5000);
    },
    async fetchPlayers() {
      try {
			  this.players = await this.playersAPI.fetch();
      } catch(e) {
        if(e instanceof APIError){
          this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
        }
      }
    },
    async fetchGroups() {
      try {
        this.groups = await this.groupsAPI.fetch();
      } catch(e) {
        if(e instanceof APIError){
          this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
        }
      }
    },
    async fetchSchedules() {
      try {
        this.schedules = await this.schedulesAPI.fetch();
      } catch(e) {
        if(e instanceof APIError){
          this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
        }
      }
    },
    async fetchHeadlines() {
      try {
        this.headlines = await this.playersAPI.getHeadlines();
      } catch(e) {
        if(e instanceof APIError){
          this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
        }
      }
    },
    async fetchFiles() {
      try {
        this.files = await this.filesAPI.fetch();
      } catch(e) {
        if(e instanceof APIError){
          this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
        }
      }
    },
    toggleGroup(id) {
      if(this.collapsedGroups.has(id))
        this.collapsedGroups.delete(id)
      else
        this.collapsedGroups.add(id);
    },

    selectSchedule(player) {
      this.openModal('selectScheduleModal', player);
    },

    async includePlayer(group, playerId) {
      try {
        await this.groupsAPI.include(group.id, playerId);
      } catch(e) {
        this.messagePopup.show("Unable to include player");
      } finally {
        this.fetchAll(true);
      }
    },

    async saveSchedule(player, scheduleId) {
      try {
        await this.playersAPI.assign(player.id, scheduleId);
      } catch(e) {
        this.messagePopup.show("Unable to set schedule for player");
      } finally {
        this.fetchAll(true);
      }
    },
    async excludePlayer(group, player) {
      try {
        await this.groupsAPI.exclude(group, player);
      } catch(e) {
        this.messagePopup.show("Unable to exclude player");
      } finally {
        this.fetchAll(true);
      }
    },
    addPlaylist(group) {
      // this.openModal('groupInclude', group);
      this.$refs.addPlayerModal.open(group);
    },
    toSchedule(item) {
      this.clickedItem = item
      this.openSchedule = true
    },
    async changeName(name, player) {
      try {
        console.log(name, player);
        await this.playersAPI.rename(player.id, name);
        this.messagePopup.show("Устройство успешно переименовано!");
      } catch(e) {
        this.messagePopup.show("Ошибка сервера: Не удалось переименовать устройство");
      } finally {
        this.closeModal('playerRename');
        this.fetchAll(true);
      }
    },
    async createGroup(name) {
      try {
        this.groupsAPI.create(name);
        this.messagePopup.show("Группа успешно создалась!");
      } catch(e) {
        this.messagePopup.show("Ошибка сервера: Не удалось создать группу");
      } finally {
        this.closeModal('groupCreate');
        this.fetchAll(true);
      }
    },
    openDelete(item) {
      this.openModal('groupRemove', item);
    },
    async delGroup(item) {
      try {
        this.groupsAPI.remove(item.id);
        this.messagePopup.show("Группа успешно удалена!");
      } catch(e) {
        this.messagePopup.show("Ошибка сервера: Не удалось удалить группу");
      } finally {
        this.closeModal('groupRemove');
        this.fetchAll(true);
      }
    }
  }
}
</script>
<style>
  :root {
    --group-height: 47px;    
  }
</style>
<style scoped>
  @media (max-width: 414px) {
    .item td .device, .item td .device .number {
      font-size: 12px ;
    }
  }
	.main_content {
		width: 100%;
    overflow-y: scroll;
	}
	.main_content .content {
		display: flex;
		align-items: center;
    width: 100%;
    padding: 3px;
	}
  
  .delete {
    background-color: #e73c3c;
  }
  .btn-green {
    margin-top: 10px;
    margin-left: 10px;
    background-color: #3fc23f;
    border-radius: 6px;
    font-size: 16px;
    width: 140px;
  }
  .btn-move {
    background-color: #4391d2;
  }
  .btn-info {
    background-color: #49b4e2;
  }
  .btn-edit {
    background-color: #ffc61c;
  }
  .btn-arrow {
    background-color: #60db27;
  }
  .btn-block {
    background-color: #ff911c;
  }
  
/** legacy ds styles */
.list-item {
	display: flex;
	align-items: center;
	padding: 5px 10px 5px 5px;
	box-sizing: border-box;
	border-bottom: 1px solid #DDD;
}
.list-item-caption {
	flex-grow: 1;
	padding: 10px;
	min-height: 56px;
}
.list-item-buttons > button {
	margin-left: 5px;
	padding: 5px 15px;
	background: #EEE;
	color: #111;
	padding: 6px;
	border: 0;
	border-radius: 4px;
	font-family: inherit;
}
.list-item-buttons > button > img {
	vertical-align: middle;
}
.list-item-buttons > button:hover {
	background: #CCC;
}
.list-item-icon {
	width: 96px;
	align-self: stretch;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto 48px;
}
.list-item-icon-player {
  background-image: url("@/assets/imgs/playlist/monitor.svg");
}

.player-status-offline {
	opacity: .5;
}

.player-status-deadline {
	background-color: #FCB;
}

.list-group-header {
	background-color: #EEE;
}
.list-group-header > .list-item-caption {
	min-height: auto;
  font-weight: bold;
}
.list-group-header > .list-item-icon {
	display: none;
}
</style>
