const text = {
    time_now: 'сейчас',
    time_day: ['день','дня','дней'],
    time_hour: ['час','часа','часов'],
    time_minute: ['минута','минуты','минут'],
    time_ago: 'назад',
}
export function formatAmount(num, one, two, five){
	if(num > 4 && num < 20){
		return num.toString() + ' ' + five;
	} else {
		switch(num % 10){
		case 1:
			return num.toString() + ' ' + one;
		case 2:
		case 3:
		case 4:
			return num.toString() + ' ' + two;
			break;
		default:
			return num.toString() + ' ' + five;
		}
	}
}
export function DateFormatter(text){
	return (new Date(parseInt(text)*1000)).toLocaleString();
}
export function DateDifference(timestamp){
	var result = '';
	var now = new Date();
	var then = new Date(parseInt(timestamp)*1e3);
    if(isNaN(then)) return '-';
	var days = Math.floor((now - then)/86400000);
	var hours = Math.floor((now - then)/3600000)%24;
	var minutes = Math.floor((now - then)/60000)%60;
	if(hours < 0){
		result = text.time_now;
	} else if(days != 0){
		result = days + ' ' + formatAmount(days, text.time_day[0], text.time_day[1], text.time_day[2]) + ' ' + hours + ' ' + formatAmount(hours, text.time_hour[0], text.time_hour[1], text.time_hour[2]) + ' ' + minutes + ' ' + formatAmount(minutes, text.time_minute[0], text.time_minute[1], text.time_minute[2]) + ' ' + text.time_ago;
	} else if(hours != 0){
		result = hours + ' ' + formatAmount(hours, text.time_hour[0], text.time_hour[1], text.time_hour[2]) + ' ' + minutes + ' ' + formatAmount(minutes, text.time_minute[0], text.time_minute[1], text.time_minute[2]) + ' ' + text.time_ago;
	} else if(minutes != 0){
		result = minutes + ' ' + formatAmount(minutes, text.time_minute[0], text.time_minute[1], text.time_minute[2]) + ' ' + text.time_ago;
	} else {
		result = text.time_now;
	}
	return result;
}