// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/playlist/playlist.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_content[data-v-30265f58]{overflow-y:auto}.modal_container[data-v-30265f58]{width:100%;height:100%;background-color:#000;opacity:.7;z-index:1}.list-item-buttons[data-v-30265f58]{display:flex}.item[data-v-30265f58],.item .td .calendar span svg[data-v-30265f58]{transition:all .3s ease-in-out;cursor:pointer}.main_content[data-v-30265f58]{width:100%;height:100%}.main_content .content[data-v-30265f58]{display:flex;align-items:center;padding:5px 5px 0 10px;width:100%}.table .td[data-v-30265f58]{text-align:center;padding:0 10px;margin-right:15px}.table[data-v-30265f58]{width:100%;height:100%;display:flex;flex-direction:column}.table .item[data-v-30265f58]{display:flex;align-items:center;padding:10px 10px 10px 5px;box-sizing:border-box;border-bottom:1px solid #ddd}.btns .btn-danger[data-v-30265f58]{background-color:#e83030;font-size:18px;padding:7px 12px}.calendar-block[data-v-30265f58]{background-repeat:no-repeat;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");cursor:pointer;height:48px;width:60px}.btn-edit[data-v-30265f58]{background-color:#54c341;padding:0 60px}.title-list[data-v-30265f58]{display:flex;flex-direction:column;justify-content:start;width:100%;font-size:15px}.title-list .date[data-v-30265f58]{font-weight:400}.title-list span[data-v-30265f58]{font-weight:700}.btn-move[data-v-30265f58]{background-color:#3f85c2;height:35px}.btn-move svg[data-v-30265f58]{transform:rotate(270deg)}.btn.delete[data-v-30265f58]{background-color:#e73c3c}#changeName[data-v-30265f58]{text-align:left;width:100%;padding:10px;padding-left:0;font-size:16px;border:0;outline:none}.btn-save[data-v-30265f58]{background-color:#54c341;float:left;transition:all .3s ease-in-out}.btn-save[data-v-30265f58]:hover{background-color:#48b136}.change_name[data-v-30265f58]{width:57px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
