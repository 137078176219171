<template>
	<div class="main_content">
    <!-- Modal windows -->
    <CreateTask
      ref="createTaskModal"
      :title="'Добавить новую задачу'"
      @add-task="createTask" />
    
    <EditPlaylistTask
      ref="editPlaylistModal"
      :files="files"
      :disableDefaultTask="schedule.hasDefaultTask"
      @update-task="updateTask"
      />
    <EditURLTask
      ref="editURLModal"
      :disableDefaultTask="schedule.hasDefaultTask"
      @update-task="updateTask"
      />
    <EditSleepTask
      ref="editSleepModal"
      :disableDefaultTask="schedule.hasDefaultTask"
      @update-task="updateTask"
      />
    <ConfirmModal
      ref="saveWarningModal"
      title="Предупреждение"
      text="Расписание было изменено, вы действительно хотите продолжить без сохранения"
      okText="Продолжить без сохранения"
      cancelText="Вернуться к редактированию"
      @confirm="leaveWithoutSave"
      />

    <Header :title="'Редактирование плейлиста '+ title">
      <button class="btn" @click="openModal('createTaskModal', null)">Добавить задачу</button>
      <button class="btn" :class="{'btn-primary': scheduleUpdated}" @click="saveSchedule">Сохранить плейлист</button>
    </Header>
		<div class="content">
			<div class="list-item" @click="openTask(task.realTask, i)" v-for="(task, i) in formattedTasks" :key="i">
        <div class="list-item-icon" :style="{'background-image': 'url(' + task.icon + ')'}"></div>
				<div class="list-item-caption">
          <div><b>{{ task.name }}</b></div>
          <div>{{ task.description }}</div>
          <div>{{ task.schedule }}</div>
        </div>
        <div class="list-item-buttons">
          <button @click.stop="openTask(task.realTask, i)" type="button" title="Изменить"><img src="/static/icons/rename.svg"></button>
          <button @click.stop="removeTask(task.realTask)" type="button" title="Удалить"><img src="/static/icons/delete.svg"></button>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive } from 'vue'

import CreateModal from '@/components/CreateModal.vue'
import Header from '@/components/Header.vue'
import EditPlaylistTask from '@/components/PlayList/EditPlaylistTask.vue'
import CreateTask from '@/components/PlayList/CreateTask.vue'

import { Schedule, PlaylistTask, SleepTask, VideoTask, SlideshowTask, DocumentTask, StreamTask, WebTask, DailyTime, URLTask, PlayUntilTime } from '@/api/ScheduleModel'
import ViewWithModalsMixin from '@/mixins/ViewWithModalsMixin'
import EditURLTask from '@/components/PlayList/EditURLTask.vue'
import EditSleepTask from '@/components/PlayList/EditSleepTask.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import { formatAmount } from '@/utils/formatters'

export default {
  name: 'ScheduleEditor',
  inject: ['schedulesAPI', 'filesAPI', 'messagePopup'],
  mixins: [ViewWithModalsMixin],
  components: {
    CreateModal,
    Header,
    CreateTask,
    EditPlaylistTask,
    EditURLTask,
    EditSleepTask,
    ConfirmModal
},
  data: () => ({
    scheduleUpdated: false,
    schedule: reactive(new Schedule()),
    files: [],
    title: '',
    text_warning: null,
  }),
  mounted() {
    this.fetchSchedule()
    this.fetchFiles()
    window.addEventListener('beforeunload', this.unloadHandler);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.unloadHandler);
  },
  computed: {
    currentSchedule: function() {
        return this.$route.params.id;
    },
    formattedTasks: function(){
      return this.schedule.tasks.map((t) => {
        let description;
        if(t instanceof PlaylistTask) {
          let size = t.calculateSize(this.files);
          description = formatAmount(size, 'элемент', 'элемента', 'элементов');
        } else {
          description = t.url;
        }
        return {
          icon: this.getTaskIcon(t),
          name: t.label,
          description: description,
          schedule: t.schedule.toString(),
          realTask: t
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if(this.scheduleUpdated) {
      this.openModal('saveWarningModal', next);
    } else {
      next();
    }
  },
  methods: {
    unloadHandler(e) {
      if(this.scheduleUpdated){
        e.preventDefault()
      }
    },
    async fetchSchedule() {
        try {
            let schedule = await this.schedulesAPI.read(this.currentSchedule);
            this.schedule.import(schedule.schedule);
            this.title = schedule.name;
        } catch(e) {
            console.warn('Failed to load schedule')
        }
    },
    async fetchFiles() {
        try {
          this.files = await this.filesAPI.fetch();
        } catch(e) {
          console.warn('Failed to load files')
        }
    },
    getTaskIcon(task) {
        if(task instanceof VideoTask) {
          return require('@/assets/imgs/playlist/type-video.svg')
        } else if(task instanceof SlideshowTask) {
          return require('@/assets/imgs/playlist/slideshow.svg')
        } else if(task instanceof DocumentTask) {
          return require('@/assets/imgs/playlist/document.svg')
        } else if(task instanceof StreamTask) {
          return require('@/assets/imgs/playlist/type-stream.svg')
        } else if(task instanceof WebTask) {
          return require('@/assets/imgs/playlist/type-web.svg')
        } else if(task instanceof SleepTask) {
          return require('@/assets/imgs/playlist/type-monitor.svg')
        }
    },
    isPlaylist(task) {
      return task instanceof PlaylistTask
    },
    updateTask(task, index) {
      try {
        this.schedule.updateTask(index, task);
        this.scheduleUpdated = true;
      } catch(e) {
        this.messagePopup.show('Ошибка при настройке задачи: ' + e.toString());
        this.openTask(task, index);
      }
    },
    openTask(task, index) {
      if(task instanceof PlaylistTask) {
        this.openModal('editPlaylistModal', { index, task });
      } else if(task instanceof URLTask) {
        this.openModal('editURLModal', { index, task });
      } else {
        this.openModal('editSleepModal', { index, task });
      }
    },
    removeTask(item) {
      this.schedule.removeTask(item);
      this.scheduleUpdated = true;
    },
    createTask(task) {
      if(this.schedule.hasDefaultTask) {
        task.schedule = new PlayUntilTime(new DailyTime(0, 0), new DailyTime(12, 0))
      }
      if(task instanceof SlideshowTask){
        task.delay = 10;
      }
      let taskIndex = this.schedule.addTask(task);
      this.scheduleUpdated = true;
      this.openTask(task, taskIndex);
    },
    async saveSchedule() {
        try {
          await this.schedulesAPI.save(this.currentSchedule, this.schedule.export());
          this.scheduleUpdated = false;
        } catch(e) {
          this.messagePopup.show('Не удалось сохранить расписание');
        } finally {
          this.fetchSchedule();
        }
    },
    async leaveWithoutSave(next) {
      next()
    }
  },
}
</script>
<style scoped>
  .modal_container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: .7;
    z-index: 1;
  }
	.main_content {
		width: 100%;
    height: 100%;
	}
	.main_content .content {
		display: flex;
    flex-direction: column;
		align-items: center;
		padding: 5px 0 0 10px;
    width: 100%;
	}
	table th {
		font-weight: 400;
		font-size: 18px;
		padding: 0 10px 10px 10px;
		text-align: center;
	}
	table td {
		text-align: center;
		padding: 0 10px;
	}
	table td {
		margin: 8px 0;
	}
	.btns {
		display: flex;
		align-items: center;
    justify-content: end;
	}
  .icons {
    cursor: pointer;
  }
  .title-list {
    display: flex;
    flex-direction: column;
    padding: 10px;
    min-height: 56px;
    text-align: left;
    font-size: 14px;
  }
  .title-list .title {
    font-weight: bold;
  }
  .calendar-block {
    width: 70px;
  }
  .list-item-buttons {
    display: flex;
  }

  #changeName {
    text-align: left;
    width: 100%;
    padding: 10px;
    padding-left: 0px;
    font-size: 16px;
    border: 0;
    outline: none;
  }
  .title_row {
    width: 100%;
  }
  .title-list .info_list {
    font-size: 14px;
    font-weight: 400;
  }
  .list-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 5px;
    box-sizing: border-box;
    border-bottom: 1px solid #DDD;
    cursor: pointer;
  }
</style>
