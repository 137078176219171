<template>
    <div @click="close" class="modal_container" v-if="visible">
        <div @click.stop class="modal">
            <div class="head">
                <span>{{ title }}</span>
                <img @click="close" src="@/assets/imgs/playlist/delete.svg" />
            </div>
            <div class="content">
                <div class='media-name'>Сейчас показывается: {{ currentResource === null ? status.process_status : currentResource.name }}</div>
                <div class='media-element-wrapper' v-if='currentResource !== null && currentResource.filetype == "video"'>
                    <video class='media-element' ref="player" :src="resourcePreviewUrl" controls muted></video>
                </div>
                <div class='media-element-wrapper' v-else-if='currentResource !== null && currentResource.filetype == "image"'>
                    <img class='media-element' :src="resourcePreviewUrl" />
                </div>
                <div class='media-element-wrapper' v-else>
                    <img class='media-element' src="@/assets/imgs/no-image.png" />
                </div>
            </div>
            <div class="footer">
                <button @click="close" class="btn">Закрыть</button>
            </div>
        </div>
    </div>
</template>

<script>
import StatefulModalMixin from '@/mixins/StatefulModal'

export default {
    name: 'PreviewModal',
    mixins: [StatefulModalMixin],
    inject: ['playersAPI'],
    props: {
        title: String,
        files: Object
    },
    data: () => ({
        updateStatus: null,
        status: {}
    }),
    methods: {
        async fetchPlayerInfo(updateInputs){
            let status = await this.playersAPI.status(this.state.id);
            this.status = status.stats;
            this.updateStatus = setTimeout(this.fetchPlayerInfo.bind(this), 5000);
        },
    },
    computed: {
        currentResource: function(){
            if(['play', 'slideshow'].includes(this.status.process_executable)){
                if(!(this.status.process_status in this.files))
                    return null;
                return this.files[this.status.process_status];
            } else {
                return null;
            }
        },
        resourcePreviewUrl: function(){
            if(this.currentResource !== null){
                return '/media/' + this.status.process_status;
            } else {
                return '/assets/imgs/no-image.jpg';
            }
        },
    },
    watch: {
        resourcePreviewUrl: function(){

            if((this.currentResource != null) && (this.currentResource.filetype == "video")){
                setTimeout(() => {
                    this.$refs.player.play();
                }, 100);
            }
        },
        visible: function(isOpened){
            if(isOpened) {
                this.fetchPlayerInfo(true);
            } else {
                this.status = {};
                clearTimeout(this.updateStatus);
            }
        }
    },
}
</script>
<style scoped>  
  .modal_container {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
  }
  .modal {
    max-width: 960px;
    width: 100%;
    height: 90%;
    overflow: hidden;
    background: #EFEFEF;
    border: 1px solid #080808;
    box-sizing: border-box;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    z-index: 3;
    display: flex;
    flex-direction: column;
  } 
  .img_container {
    width: 100%;
    height: 268px;
  } 
  .img_container img, .img_container video {
    height: 100%;
  }
  .modal .head {
    background: #555;
    color: #FFF;
    margin: 0;
    padding: 10px;
    font-size: 1.3em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .head img {
    filter: invert(1);
    cursor: pointer;
  }
  .modal .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 6px 10px;
    text-align: right;
    border-top: 1px solid #CCC;
    background-color: #efefef;
  }
  .modal .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .footer .btn:hover {
    background: #737373;
  }
  .btn:first-child {
    margin-right: 5px;
  }
  .footer .btn {
    font: 400 10pt "Golos Text", "Open Sans", "Segoe UI", "Liberation Sans", sans-serif;
    background: #888;
    color: #fff;
    border: 0;
    border-radius: 3px;
    padding: 5px 10px;
    vertical-align: bottom;
    min-width: 120px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: .3s all ease-in-out;
  }
    .media-element {
        margin: auto;
        flex-grow: 1;
        max-width: 100%;
        max-height: 100%;
    }
    img.media-element {
        object-fit: contain;
    }

  .media-element-wrapper {
      flex-grow: 1;
      display: flex;
      background: #000;
      overflow: hidden;
      flex-direction: column;
  }
  .media-name {
      background: #000;
      color: #FFF;
      padding: 5px;
  }


</style>
