<template>
	<div class="main_content">
    <InputModal
      ref="createPlaylistModal"
      title="Создать новый плейлист"
      @submit="createPlaylist"
      buttonName="Создать" />
    <InputModal
      ref="renameModal"
      title="Переименовать плейлист"
      buttonName="Переименовать"
      @submit="renamePlaylist" />
    <ConfirmModal
      ref="deleteModal"
      title="Удаление плейлиста"
      text="Вы действительно хотите удалить этот плейлист: "
      okText="Удалить"
      type="delete"
      @confirm="deletePlaylist" />
    <SelectGroupModal
      ref="selectGroupModal"
      :groups="groups"
      @select-group="selectPlaylistForGroup" />

    <Header>
      <button class="btn btn-primary" @click="openModal('createPlaylistModal', null)">Создать плейлист</button>
    </Header>
		<div class="content">
			<div class="table" width="100%">
				<div class="item" @click.stop="toEditPage(item.id)" v-for="item in displayedPlaylists" :key="item.id">
					<div class="td calendar-block"></div>
          <div class="title-list">
            <span v-show="!item.edit">{{ item.name }}</span>
            <span class="date">Последние изменение: {{ item.modified }}</span>
          </div>
          <div class="list-item-buttons">
            <button @click.stop="toEditPage(item.id)" title="Настроить"><img src="@/assets/imgs/playlist/settings.svg"></button>
            <button @click.stop="openModal('selectGroupModal', item)" title="Установить на плеерах"><img src="@/assets/imgs/playlist/schedule.svg"></button>
            <button @click.stop="openModal('renameModal', item, item.name)" title="Переименовать"><img src="@/assets/imgs/playlist/rename.svg"></button>
            <button @click.stop="copyPlaylist(item)" title="Скопировать"><img src="@/assets/imgs/playlist/copy.svg"></button>
            <button @click.stop="openModal('deleteModal', item)" title="Удалить"><img src="@/assets/imgs/playlist/delete.svg"></button>
          </div>
				</div>
		  </div>
		</div>
	</div>
</template>

<script>
import ViewWithModals from '@/mixins/ViewWithModalsMixin'
import Header from '@/components/Header.vue'
import { DateFormatter } from "@/utils/formatters"
import InputModal from '@/components/InputModal.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import SelectGroupModal from '@/components/Devices/SelectGroupModal.vue'

export default {
  name: 'Playlists',
  inject: ['schedulesAPI', 'groupsAPI', 'messagePopup'],
  mixins: [ViewWithModals],
  components: {
    InputModal,
    Header,
    SelectGroupModal,
    ConfirmModal
  },
  data: () => ({
    playlists: [],
    groups: [],
    playlistsUpdater: null
  }),
  computed: {
    displayedPlaylists: function(){
      return this.playlists.map((p) => {
        return {
          id: p.id,
          name: p.name,
          modified: DateFormatter(p.lastmod)
        }
      })
    }
  },
  mounted() {
    this.fetchPlaylists()
    this.fetchGroups()
  },
  unmounted() {
    clearTimeout(this.playlistsUpdater);
  },
  methods: {
    async fetchGroups() {
      this.groups = await this.groupsAPI.fetch();
    },
    async fetchPlaylists() {
      try {
        this.playlists = await this.schedulesAPI.fetch();
        this.playlistsUpdater = setTimeout(this.fetchPlaylists.bind(this), 5000);
      } catch(e) {
        this.messagePopup.show("Ошибка сервера: Не удалось получить плейлисты");
      }
    },
    async createPlaylist(name) {
      try {
        let playlistId = await this.schedulesAPI.create(name)
        // NOTE: or just scroll to
        this.$router.push(`/edit/${playlistId}`)
      } catch(e) {
        this.messagePopup.show("Не удалось создать плейлист")
      }
    },
    async renamePlaylist(name, playlist) {
      try {
        await this.schedulesAPI.rename(playlist.id, name)
      } catch(e) {
        this.messagePopup.show("Не удалось переименовать плейлист")
      } finally {
        this.fetchPlaylists();
      }
    },
    async deletePlaylist(playlist) {
      try {
        await this.schedulesAPI.remove(playlist.id)
      } catch(e) {
        this.messagePopup.show("Не удалось удалить плейлист")
      } finally {
        this.fetchPlaylists();
      }
    },
    async copyPlaylist(playlist) {
      let newName = playlist.name;
      for(var i = 2; i < 100; i++){
          newName = playlist.name + ' - ' + i.toString();
          const dublicates = this.playlists.filter(function(x){
              return x.name == newName;
          }).length;
          if(dublicates < 1) break;
      }
      try {
        await this.schedulesAPI.copy(playlist.id, newName)
      } catch(e) {
        this.messagePopup.show("Не удалось сделать копию плейлиста")
      } finally {
        this.fetchPlaylists();
      }
    },
    async selectPlaylistForGroup(playlist, groupId) {
      try {
        await this.groupsAPI.assign(groupId, playlist.id)
      } catch(e) {
        this.messagePopup.show("Не удалось удалить плейлист")
      } finally {
        this.fetchPlaylists();
      }
    },
    toEditPage(id) {
      this.$router.push(`/edit/${id}`)
    },
  },
}
</script>
<style scoped>
  .main_content {
    overflow-y: auto;
  }
  .modal_container {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .7;
    z-index: 1;
  }
  .list-item-buttons {
    display: flex;
  }
  .item, .item .td .calendar span svg {
    transition: .3s all ease-in-out;
    cursor: pointer;
  }
	.main_content {
		width: 100%;
    height: 100%;
	}
	.main_content .content {
		display: flex;
		align-items: center;
		padding: 5px 5px 0 10px;
    width: 100%;
	}
	.table .td {
		text-align: center;
		padding: 0 10px;
    margin-right: 15px;
	}
  .table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .table .item {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 5px;
    box-sizing: border-box;
    border-bottom: 1px solid #DDD;
  }
	.btns .btn-danger {
		background-color: #e83030;
		font-size: 18px;
		padding: 7px 12px;
	}
  .calendar-block {
    background-repeat: no-repeat;
    background-image: url("@/assets/imgs/playlist/playlist.svg");
    cursor: pointer;
    height: 48px;
    width: 60px;
  }
  .btn-edit {
    background-color: #54c341;
    padding: 0px 60px;
  }
  .title-list {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    font-size: 15px;
  }
  .title-list .date {

    font-weight: 400;
  }
  .title-list span {
    font-weight: bold;
  }
  .btn-move {
    background-color: #3f85c2;
    height: 35px;
  }
  .btn-move svg {
    transform: rotate(270deg);
  }
  .btn.delete {
    background-color: #e73c3c;
  }
  #changeName {
    text-align: left;
    width: 100%;
    padding: 10px;
    padding-left: 0px;
    font-size: 16px;
    border: 0;
    outline: none;
  }
  .btn-save {
    background-color: #54c341;
    float: left;
    transition: .3s all ease-in-out;
  }
  .btn-save:hover {
    background-color: #48b136;
  }
  .change_name {
    width: 57px;
  }
</style>
