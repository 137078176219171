// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/playlist/monitor.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:414px){table tbody tr td[data-v-722b872e]{font-size:12px!important}}@media (max-width:924px){table td[data-v-722b872e],table tr th[data-v-722b872e]{font-size:14px}table td .device span[data-v-722b872e]:last-child{font-size:9px}.replay[data-v-722b872e]{font-size:12px;margin-left:10px}}.main_content[data-v-722b872e]{width:100%}a.edit[data-v-722b872e]{color:#28d;text-decoration:underline;cursor:pointer}.list_icon[data-v-722b872e]{width:96px;align-self:stretch;background-position:50%;background-repeat:no-repeat;background-size:auto 48px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.main_content .content[data-v-722b872e]{width:100%;padding:6px;overflow-y:auto}.content table[data-v-722b872e]{width:100%;border-collapse:collapse}td.playlist[data-v-722b872e]{vertical-align:middle}table th[data-v-722b872e]{font-weight:400;font-size:18px;padding:0 10px 10px 0;text-align:center}table thead tr th[data-v-722b872e]{padding:10px;vertical-align:middle;text-align:left;font-size:14px;white-space:nowrap;border-bottom:2px solid #ccc;color:#000}table tbody tr[data-v-722b872e]{align-items:center;max-height:67px;height:67px}table tbody tr td[data-v-722b872e]{text-align:left;padding:15px 10px;padding-left:0;font-size:15px;max-height:67px}.replay[data-v-722b872e]{font-weight:700;font-size:14px;margin-left:15px}.device[data-v-722b872e]{display:flex;justify-content:start;align-items:center;flex-wrap:nowrap}.device.active span[data-v-722b872e]{opacity:1;color:#000914}.device span[data-v-722b872e]{font-weight:700;opacity:.5}.device.active img[data-v-722b872e]{opacity:1}.device img[data-v-722b872e]{opacity:.5}.device .title[data-v-722b872e]{margin:0 10px}.device.active .number[data-v-722b872e]{opacity:.7}.device .number[data-v-722b872e]{opacity:.3;font-size:12px;color:#898c8f;font-weight:300}.list-item-buttons[data-v-722b872e]{display:flex}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
