<template>
  <div class="modal_window" @click="close" v-if="visible">
    <div class="modal_container">
      <div class="modal" @click.stop>
        <div class="head">
          <span>{{title}}</span>
        </div>
        <div class="content">
          <div class="desc" style="font-size: 75%; padding: 5px 10px;">
					Допустимые файлы для загрузки:
					<ul>
						<li>Изображения в форматах: png, jpeg
						</li><li>Видео в форматах: mp4, mkv, mpeg, wmv, flv, webm, avi, mov
						</li><li>Документы в форматах: pdf, doc, docx, rtf, odt, ppt, pptx, odp, xls, xlsx, ods
					  </li>
          </ul>
          <b>
            Возможен выбор для загрузки нескольких файлов одновременно
          </b>
				</div>
          <form 
            :class="{'dragged': isDragging}"
            id="uploadForm"
          >
          	<label for="upfile" id="selectfile" class="btn" style="width: 100%">Выберите файлы</label>
          	<input type="file" ref="file" @change="onFileUpload" id="upfile" name="upfile" class="uploaderHidden" multiple accept="image/*,video/*,.pdf,.doc,.docx,.ppt,.pptx,.rtf,.odt">
          	<div id="uploadList"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop.stop="drop"
              class="listview"
            >
              <div v-if="filesToUpload.length === 0" class="listview-item" style="opacity: 0.3;">Нет элементов</div>
              <div v-else v-for="item in filesToUpload" :key="item.name" class="listview-item">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </form>
        </div>
        <div class="footer">
          <div v-if="loading" class="loading-status">
            <span>Загрузка {{loadingText}}</span>
          </div>
          <div class="buttons">
            <button @click="saveFile" class="btn">Загрузить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatefulModal from '@/mixins/StatefulModal';

export default {
  name: 'UploadFile',
  mixins: [StatefulModal],
  data: () => ({
    openList: false,
    isDragging: false,
    name: "",
    loadingTime: 0,
    loadingText: "",

    filesToUpload: []
  }),
  props: {
    title: String,
    folder: String,
    loading: Boolean,
  },
  mounted() {
  },
  unmounted() {
  },
  watch: {
    loading: function(value){
      if(value){
        this.startLoading();
      }
    },
    visible: function(isOpen) {
      if(!isOpen) {
        this.filesToUpload = [];
      }
    }
  },
  methods: {
    startLoading() {
      this.loadingText = "...".substring(0, this.loadingTime);
      this.loadingTime = this.loadingTime % 3 + 1;
      if(this.loading)
        setTimeout(this.startLoading.bind(this), 300);
    },
    saveFile() {
      if(this.filesToUpload.length < 1) return;
      this.$emit('savefile', this.filesToUpload, this.folder);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      Array.prototype.forEach.call(e.dataTransfer.files, (f) => {
        this.filesToUpload.push(f);
      });
      this.isDragging = false;
    },
    onFileUpload() {
      let files = document.getElementById("upfile").files;
      Array.prototype.forEach.call(files, (f) => {
        this.filesToUpload.push(f);
      });
    },
  },
}
</script>
<style scoped>  
  #uploadForm .uploaderHidden {
    display: none;
  }
  #uploadForm {
    width: 100%;
  }
  .dragged {
    border: 1px solid #7e7e7e;
    border-radius: 4px 4px 0px 0px;
  }
  .listview-item {
    font-size: 10pt;
    cursor: default;
    background-repeat: no-repeat;
    background-position: 8px center;
    padding: 10px 12px 10px 36px;
    color: #080808;
    display: flex;
    flex-direction: column;
  }
  .desc {
    margin-left: 10px;
    color: #080808;
    font-size: 11px;
    margin: 0;
  }
  .desc ul {
    margin: 10px 0px;
    margin-left: 40px;
  }
  .listview {
    max-height: 50vh;
    background-color: #FFF;
    padding: 4px;
    border: 1px solid #DEDEDE;
    display: block;
    overflow-y: auto;
  }
  .btn {
    font: 400 10pt "Open Sans", "Segoe UI", "Liberation Sans", sans-serif;
    background: #888;
    color: #fff;
    border: 0;
    border-radius: 3px 3px 0px 0px;
    padding: 5px 10px;
    vertical-align: bottom;
    min-width: 120px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
  }
  .modal_container {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
  }
  .modal .head {
    background: #555;
    color: #FFF;
    margin: 0;
    padding: 10px;
    font-size: 1.3em;
    font-weight: bold;
  }
  .modal .footer {
    width: 100%;
    padding: 10px;
    border-top: 1px solid #CCC;
    background-color: #efefef;
    display: flex;
  }
  .modal .content {
    width: 100%;
    padding: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .modal .content input {
    margin: 0;
    border: 1px solid #CCC;
    padding: 10px;
    font-size: 1.2em;
    display: block;
    width: 100%;
    box-sizing: border-box;
    outline: none;
  }

  .modal_bg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: .7;
    z-index: 1;
  }
  .footer .btn {
    font: 400 10pt "Open Sans", "Segoe UI", "Liberation Sans", sans-serif;
    background: #888;
    color: #fff;
    border: 0;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 0px 2px;
    vertical-align: bottom;
    min-width: 120px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}
.footer > .loading-status {
  flex-grow: 1;
}
.footer > .buttons {
  margin-left: auto;
}
</style>
