<template>
  <div class="modal_window" @click="closeModal">
    <div class="modal_container">
      <div class="modal" @click.stop>
        <div class="head">
          <div class=""></div>
          <h1 class="title" :class="{'hide': page.page !== currentPage}" v-for="page in pages" :key="page">
            <span v-if="page.page === currentPage">{{page.text}}</span>
          </h1>
          <img @click="closeModal" src="@/assets/imgs/playlist/delete.svg"/>
        </div>
        <div class="content">
          <div v-if="currentPage === 0" class="page page_one">
            <div class="desc">
              <span>
                <b>ММВС Digital Signage</b> — это система Цифровых Вывесок, с помощью которой Вы можете управлять несколькими экранами.
              </span>
              <br/>
              <br/>
              <span>Функции, которые выполняет система Digital Signage:</span>
              <ul class="externals">
                <li>Загрузка видео, фото, таблиц, документов;</li>
                <li>Трансляция материалов на экраны;</li>
                <li>Сортировка материалов по папкам;</li>
                <li>Создание плейлистов: настройка типа показа, времени показа, спящего режима;</li>
                <li>Установка бегущей строки.</li>
              </ul>
            <br/>
            <br/>
            <div class="hint_img img first"/>
              <span>
                <br/>
                <b> Воспроизведение </b> — содержит в себе список всех работающих плееров и мастер по их запуску.
                <br/>
                <b> Устройства </b> — содержит в себе список всех доступных плееров, а также их группы.
                <br/>
                <b> Плейлисты </b> — содержит в себе список всех плейлистов с возможностью их создания и настройки.
                <br/>
                <b> Материалы </b> — содержит в себе список всех материалов, папки с материалами и возможность их загрузки.
                <br/>
                <b> Бегущая строка</b> — список всех установленных бегущих строк на работающих плеерах.
              </span>
            </div>
          </div>

          <div v-else-if="currentPage === 1" class="page page_two">
            <div class="desc">
              <span>
                <ul class="externals digital">
                  <li>Загрузить материалы во вкладке “Материалы”;</li>
                  <li>Создать плейлист во вкладке “Плейлисты”</li>
                  <li>Перейти во вкладку <b>“Произведение”</b> и нажать на кнопку <b>“Мастер запуска плееров”</b></li>
                </ul>
              </span>
            <br/>
            <br/>
            <div class="hint_img second img"/>
            </div>
          </div>

          <div v-else-if="currentPage === 2" class="page page_third">
            <div class="desc">
              <span>
                Шаг 1: выберите один или несколько плееров и нажмите на кнопку “<b>Далее</b>”
              </span>
            <br/>
            <br/>
            <div class="hint_img img third"/>
            <br/>
              <span>
              </span>
            <br/>
            </div>
          </div>

          <div v-else-if="currentPage === 3" class="page page_four">
            <div class="desc">
              <span>
                Шаг 2: выберите нужный плейлист и нажмите на кнопку “<b>Далее</b>”
              </span>
              <br/>
              <br/>
              <div class="hint_img img four"/>
              <br/>
            </div>
          </div>
          <div v-else-if="currentPage === 4" class="page page_four">
            <div class="desc">
              <span>
                Шаг 3: установите дополнительные параметры бегущей строки и спящего режима (необязательно) и нажмите на кнопку “<b>Готово</b>”
              </span>
              <br/>
              <br/>
              <div class="hint_img img five"/>
              <br/>
              <span>
                После этих трех шагов система автоматически запустит выбранный Вами плейлист на выбранном Вами экране.
              </span>
              <br/>
              <br/>
              <span>
                Далее подробно будут описаны все вкладки в системе навигации.
              </span>
            </div>
          </div>
          <div v-else-if="currentPage === 5" class="page page_four">
            <div class="desc">
              <span>
                На этой вкладке контролируются все работающие плееры и запускаются новые.
              </span>
              <br/>
              <br/>
              <div class="hint_img img six"/>
              <br/>
              <span>
                <b>Красный прямоугольник</b> — открывается Мастер запуска плееров, о котором рассказано в прошлом разделе.
              </span>
              <br/>
              <span>
                <b>Желтый прямоугольник</b> — кнопка “<b>Установить строку на всех</b>”. При нажатии открывается окно:
              </span>
              <br/>
              <span>
               <b>Подчеркнуто зеленым</b> — указан плейлист, который запущен на плеере в данный момент. При нажатии открывается окно редактирования этого плейлиста.
              </span>
              <br/>
              <span>
                <b>Подчеркнуто оранжевым</b> — при нажатии открывается такое же окно как в пункте
              </span>
              <br/>
              <span>
                <b>Желтый прямоугольник</b>, но бегущая строка устанавливается только на одно выбранное устройство.
              </span>
              <br/>
            </div>
          </div>
          <div v-else-if="currentPage === 6" class="page page_four">
            <div class="desc">
              <div class="hint_img img seven"/>
              <span>
                В белое поле вводится текст; после нажатия кнопки “Добавить”, введенный текст появляется на каждом работающем экране в виде бегущей строки.
              </span>
            </div>
          </div>
          <div v-else-if="currentPage === 7" class="page page_four">
            <div class="desc">
              <div class="hint_img img six"/>
              <br/>
              <span><b>Красные цифры</b></span>
              <ul class="externals digital">
                <li><b>Кнопка со значком фотоаппарата — “Снимок экрана”</b>При нажатии создает снимок экрана и открывает его в отдельном окне.</li>
                <li><b>Кнопка со значком плейлиста — “Установить плейлист”</b>При нажатии открывается отдельное окошко со списком всех плейлистов. Нажав на любой из них, выбранный плейлист установится на это устройство.</li>
                <li><b>Кнопка со значком крестика — “Стоп”</b>Удаляет выбранное устройство из списка работающих устройств и, соответственно, выключает его.</li>
              </ul>
            </div>
          </div>
          <div v-else-if="currentPage === 8" class="page page_four">
            <div class="desc">
              <span>На этой вкладке приведен список всех доступных Вам устройств.</span>
              <br/>
              <span>Устройства распределяются по группам:</span>
              <ul class="externals ">
                <li>Все устройства доступные Вам (группа по-умолчанию);</li>
                <li>Дополнительные группы, которые создаются Вами.</li>
              </ul>
              <div class="hint_img img eight"/>
              <span><b>Красный прямоугольник</b> — создает дополнительную группу устройств. При создании открывается окно с полем для ввода названия группы.</span>
              <br/>
              <br/>
              <div class="hint_img img twelve"/>
            </div>
          </div>
          <div v-else-if="currentPage === 9" class="page page_four">
            <div class="desc">
              <div class="hint_img img eight"/>
              <span><b>Красные цифры:</b></span>
              <ul class="externals digital">
                <li>
                  <b>Кнопка со значком символа i — “Статус плеера”</b>
                  <br/>
                  При нажатии открывается отдельное окно со всей информацией по выбранному плееру. Внутри этого окна Вы можете выбрать плейлист, установить бегущую строку и изменить громкость воспроизведения.
                </li>
                <li>
                  <b>Кнопка со значком плейлиста — “Установить плейлист”</b> Устанавливает на выбранное устройство выбранный плейлист.
                </li>
                <li>
                  <b>Кнопка со значком карандаша — “Переименовать”</b> При нажатии открывается отдельное окно с полем для ввода нового названия для устройства.
                </li>
                <li>
                  <b>Кнопка со значком фотоаппарата — “Сделать снимок экрана”</b> Делает снимок экрана в данный момент и открывает его в отдельном окне.
                </li>
              </ul>
            </div>
          </div>
          <div v-else-if="currentPage === 10" class="page page_four">
            <div class="desc">

              <div class="hint_img img eight"/>
              <span><b>Красные цифры:</b></span>
              <ol start="5" class="externals digital">
                <li>
                  <b>Кнопка со значком символа плюс — “Добавить в группу”</b> При нажатии открывается окно со всеми доступными устройствами. Выбирая их, они добавляются в группу.
                </li>
                <li>
                  <b>Кнопка со значком крестика — “Удалить группу”</b> Удаляет выбранную группу.
                </li>
                <li>
                  <b>Кнопка со значком стрелки вверх — “Свернуть”</b> Сворачивает (или разворачивает) список всех устройств выбранной группы.
                </li>
              </ol>
            </div>
          </div>
          <div v-else-if="currentPage === 11" class="page page_four">
            <div class="desc">
              <span>На этой вкладке приведен список всех созданных плейлистов с возможность их настройки. А также создание новых плейлистов.</span>
              <br/>
              <br/>
              <div class="hint_img img ten"/>
              <br/>
              <span><b>Красные цифры:</b></span>
              <ol class="externals digital">
                <li>
                  <b>Кнопка со значком шестеренки — “Настроить”</b> При нажатии переводит на страницу настройки выбранного плейлиста.
                </li>
                <li>
                  <b>Кнопка со значком плейлиста — “Установить на плеерах”</b> Открывается отдельное окно с группами устройств, на которые можно установить выбранный плейлист.
                </li>
                <li>
                  <b>Кнопка со значком карандаша — “Переименовать”</b> Переименовывает выбранный плейлист.
                </li>
                <li>
                  <b>Кнопка со значком двух листов — “Скопировать”</b> Создает копию выбранного плейлиста
                </li>
                <li>
                  <b>Кнопка со значком крестика — “Удалить”</b> Удаляет выбранный плейлист.
                </li>
              </ol>
            </div>
          </div>
          <div v-else-if="currentPage === 12" class="page page_four">
            <div class="desc">
              <span><b>Настройка выбранного плейлиста</b></span>
              <br/>
              <br/>
              <div class="hint_img img eleven"/>
              <span>На странице настройки плейлиста добавляются, удаляются и переименовываются задачи.</span>

            </div>
          </div>
          <div v-else-if="currentPage === 13" class="page page_four">
            <div class="desc">
              <span>При нажатии <b>Зеленой кнопки “Добавить”</b> открывается отдельное окно с выбором типа задачи:</span>
              <br/>
              <br/>
              <div class="hint_img img thirteen"/>
              <br/>
              <span>Выбрав тип задачи, она появится в списке плейлиста. Нажав на любую задачу из списка плейлиста, перед Вами откроется всплывающее окно:</span>
            </div>
          </div>
          <div v-else-if="currentPage === 14" class="page page_four">
            <div class="desc">
              <br/>
              <span>Это верхняя часть окна <b>Редактирования задачи</b> — в нем настраивается основной функционал задачи.</span>
              <br/>
              <span><b>Красный прямоугольник</b> — настройка типа выполнения: по расписанию или в течение периода.</span>
              <br/>
              <span><b>Синий прямоугольник</b> — настройка повторения задачи: не повторять или повторять каждый час.</span>
              <br/>
              <div class="hint_img img edit_task"/>
              <span>Далее — два окна, в которых указывается время начала задачи(обязательно) и время завершения задачи (опционально).</span>
              <br/>
              <span>Далее разберем нижнюю часть окна <b>Редактирования задачи.</b></span>
              <br/>
              <span>Правая часть экрана содержит в себе все материалы и папки, доступные для добавления в данную задачу.</span>
              <br/>
              <span>Чтобы добавить материал в задачу, нужно либо зажать левой кнопкой мыши любой материал и перетащить его в левую часть окна, либо нажать на кнопку со значком символа <b>Плюс</b>, который есть у каждого материала.</span>
              <br/>
              <br/>
              <span>У каждой папки есть две кнопки:</span>
              <ol class="externals none">
                <li>
                  <b>Открыть папку</b>  — открывает содержимое выбранной папки.
                </li>
                <li>
                  <b>Добавить папку</b> — добавляет всю папку целиком в левую часть экрана.
                </li>
              </ol>
            </div>
          </div>
          <div v-else-if="currentPage === 15" class="page page_four">
            <div class="desc">
              <span>Далее — два окна, в которых указывается время начала задачи(обязательно) и время завершения задачи (опционально).</span>
              <br/>
              <span>Далее разберем нижнюю часть окна <b>Редактирования задачи.</b></span>
              <br/>
              <span>Правая часть экрана содержит в себе все материалы и папки, доступные для добавления в данную задачу.</span>
              <br/>
              <span>Чтобы добавить материал в задачу, нужно либо зажать левой кнопкой мыши любой материал и перетащить его в левую часть окна, либо нажать на кнопку со значком символа <b>Плюс</b>, который есть у каждого материала.</span>
              <br/>
              <br/>
              <span>У каждой папки есть две кнопки:</span>
              <ol class="externals none">
                <li>
                  <b>Открыть папку</b>  — открывает содержимое выбранной папки.
                </li>
                <li>
                  <b>Добавить папку</b> — добавляет всю папку целиком в левую часть экрана.
                </li>
              </ol>
              <br/>
              <div class="hint_img img two_window"/>
            </div>
          </div>
          <div v-else-if="currentPage === 16" class="page page_four">
            <div class="desc">
              <span>В левой части экрана можно работать с добавленными материалами.</span>
              <br/>
              <span>С помощью кнопок со стрелками <b> вверх </b> и <b>вниз</b> меняется очередность воспроизведения материалов.</span>
              <br/>
              <span>Кнопка со значком символа <b>Минус</b> убирает материал из левой части экрана в правую.</span>
              <br/>
              <span>После нажатия кнопки “Ок” плейлист сохранит все Ваши изменения и отобразит их в разделе <b>Редактирование плейлиста.</b></span>
              <br/>
              <br/>
              <span><b>После добавления задачи в плейлист, не забывайте нажать на кнопку Сохранить плейлист, иначе Ваши изменения не сохранятся</b></span>
              <br/>
              <br/>
              <div class="hint_img img save_playlist"/>
            </div>
          </div>
          <div v-else-if="currentPage === 17" class="page page_four">
            <div class="desc">
              <span>На этой вкладке расположены все доступные материалы и папки.</span>
              <br/>
              <div class="hint_img img materials"/>
              <br/>
              <span><b>Зеленая кнопка “Загрузить”</b> — при нажатии открывается всплывающее окно, позволяющее загрузить файлы в систему.</span>
              <br/>
              <span>Чтобы загрузить файл, нужно нажать на область с надписью “Выберите файлы”, либо перетащить мышкой файл с компьютера в эту же область.
              </span>
              <span>После выбора файлов, нажать на кнопку “Загрузить”</span>

            </div>
          </div>
          <div v-else-if="currentPage === 18" class="page page_four">
            <div class="desc">

              <br/>
              <span><b>Серая кнопка “Создать папку”</b> — создает папку с выбранным Вами названием.</span>
              <br/>
              <br/>

              <div class="hint_img img materials"/>
              <br/>
              <span><b>Красные цифры:</b></span>
              <ol class="externals digital">
                <li>
                  <b>Кнопка со значком глаза — “Просмотр”</b>
                  <br/>
                  <span>При нажатии открывается отдельное окно, в котором можно просмотреть или скачать выбранный файл.</span>
                </li>
                <li>
                  <b>Кнопка со значком карандаша — “Переименовать”</b>
                  <br/>
                  <span>Переименовывает выбранный файл</span>
                </li>
                <li>
                  <b>Кнопка со значком стрелки вниз — “Скачать”</b>
                  <br/>
                  <span>Скачивает выбранный файл.</span>
                </li>
                <li>
                  <b>Кнопка со значком крестика — “Удалить”</b>
                  <br/>
                  <span>Удаляет выбранный файл.</span>
                </li>
              </ol>
            </div>
          </div>
          <div v-else-if="currentPage === 19" class="page page_four">
            <div class="desc">
              <br/>
              <span>На этой вкладке устанавливаются бегущие строки на отдельные устройства, либо одним действием на все устройства сразу.</span>
              <br/>
              <br/>
              <div class="hint_img img ticker"/>
              <br/>
              <span><b>Серая кнопка “Установить для всех” </b>при нажатии открывается отдельное окно с областью ввода бегущей строки <span class="underline">для всех устройств.</span></span>
              <br/>
              <br/>
              <span>Чтобы установить бегущую строку для одного устройства, нужно нажать в область нужного Вам устройства, после чего откроется отдельное окно с областью ввода бегущей строки <span class="underline">для выбранного устройства.</span></span>
            </div>
          </div>
          <!--  -->
        </div>
        <div class="footer">
          <button
            :class="{'hideBtn': currentPage > 0}"
            @click="currentPage = currentPage - 1"
            class="btn btn_page"
          >
            Назад
          </button>
          <button
              :class="{'hideBtn': currentPage < pages.length}"
            @click="currentPage = currentPage + 1"
            class="btn btn_page"
          >
            Далее
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HintModal',
  data: () => ({
    openList: false,
    pages: [
      {page: 0, text: "О системе"},
      {page: 1, text: "Что нужно сделать для запуска"},
      {page: 2, text: "Инструкция по использованию Мастера запуска плееров"},
      {page: 3, text: "Инструкция по использованию Мастера запуска плееров"},
      {page: 4, text: "Инструкция по использованию Мастера запуска плееров"},
      {page: 5, text: "Вкладка Воспроизведение"},
      {page: 6, text: "Вкладка Воспроизведение"},
      {page: 7, text: "Вкладка Воспроизведение"},
      {page: 8, text: "Вкладка устройства"},
      {page: 9, text: "Вкладка устройства"},
      {page: 10, text: "Вкладка Плейлисты"},
      {page: 11, text: "Вкладка Плейлисты"},
      {page: 12, text: "Вкладка Плейлисты"},
      {page: 13, text: "Вкладка Плейлисты"},
      {page: 14, text: "Вкладка Плейлисты"},
      {page: 15, text: "Вкладка Плейлисты"},
      {page: 16, text: "Вкладка Плейлисты"},
      {page: 17, text: "Вкладка материалы"},
      {page: 18, text: "Вкладка материалы"},
      {page: 19, text: "Вкладка Бегущая строка"},
    ],
    currentPage: 0,
    name: "",
    size_window: {
      width: 0,
      height: 0,
    }
  }),
  props: {
    title: String
  },
  unmounted() {
    document.removeEventListener('keyup', this.swipePage)
  },
  mounted() {
    this.size_window.width = screen.width
    this.size_window.height = screen.height
    document.addEventListener('keyup', this.swipePage)
  },
  methods: {
    swipePage(e) {
        console.log(e.key)
      if(e.key==='ArrowLeft' || e.key==='ArrowDown') {
        if(this.currentPage > 0) {
          this.currentPage = this.currentPage - 1 
        }
      } else if (e.key==='ArrowRight' || e.key==='ArrowUp') {
        if(this.currentPage < 3) {
          this.currentPage = this.currentPage + 1 
        }
      } else if (e.key==='Escape') {
        this.closeModal()
      }
    },
    closeModal() {
      this.$emit('closemodal')
    },
  },
}
</script>
<style scoped>
  @media (max-height: 624px) {
    .content .hint_img {
      height: 325px;
    }
    .page_four .hint_img, .page_third .desc .hint_img {
      height: 305px;
    }
  }
  @media (max-height: 762px) {
    .content .modal_container .modal {
      margin-bottom: 0%;
      justify-content: flex-start;
    }
  }
  @media (max-width: 414px) {
    .page_two .desc .hint_img {
      height: 110px;
    }
    .page_third .desc .hint_img {
      height: 240px;
    }
    .desc .hint_img {
      width: 100%;
      height: 250px;
    }
    .desc .hint_img img {
      width: 100%;
    }
    .modal_container .modal .footer {
      bottom: 0%;
    }
    .modal_container .modal .content {
      height: 100%;
    }
    .modal_window .modal_container .modal {
      height: auto;
      padding: 20px;
      overflow-x: hidden;
    }
    .modal_window .modal_container {
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 100%;
    }
  }
  .img {
    width: 100%;
    display: flex;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .hint_img.first {
    background-image: url("@/assets/imgs/hint/image_one.png");
    height: 20rem;
  }
  .hint_img.second {
    background-image: url("@/assets/imgs/hint/second_image.png");
  }
  .hint_img.third {
    height: 610px;
    background-image: url("@/assets/imgs/hint/third_image.png");
  }
  .hint_img.four {
    height: 40rem;
    background-image: url("@/assets/imgs/hint/four_img.png");
  }
  .hint_img.five {
    height: 525px;
    background-image: url("@/assets/imgs/hint/five.png");
  }
  .hint_img.six {
    height: 20rem;
    background-image: url("@/assets/imgs/hint/six.png");
  }
  .hint_img.seven {
    height: 20rem;
    background-image: url("@/assets/imgs/hint/seven.png");
  }
  .hint_img.eight {
    height: 20rem;
    background-image: url("@/assets/imgs/hint/eight.png");
  }
  .hint_img.ten {
    height: 20rem;
    background-image: url("@/assets/imgs/hint/ten.png");
  }
  .hint_img.eleven {
    height: 20rem;
    background-image: url("@/assets/imgs/hint/eleven.png");
  }
  .hint_img.twelve {
    height: 10rem;
    background-image: url("@/assets/imgs/hint/twelve.png");
  }
  .hint_img.thirteen {
    height: 26rem;
    background-image: url("@/assets/imgs/hint/thirteen.png");
  }
  .hint_img.edit_task {
    height: 8rem;
    background-image: url("@/assets/imgs/hint/edit_task.png");
  }
  .hint_img.two_window {
    height: 26rem;
    background-image: url("@/assets/imgs/hint/two_window.png");
  }
  .hint_img.save_playlist {
    height: 26rem;
    background-image: url("@/assets/imgs/hint/save_playlist.png");
  }
  .hint_img.materials {
    height: 26rem;
    background-image: url("@/assets/imgs/hint/materials.png");
  }
  .hint_img.ticker {
    height: 20rem;
    background-image: url("@/assets/imgs/hint/ticker.png");
  }
  .head img {
    cursor: pointer;
  }
  .footer .btn svg {
    position: absolute;
    top: 32%;  /* position the top  edge of the element at the middle of the parent */
    left: 46%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    transition: .3s ease-in-out all;
    transform: rotate(90deg);
  }
  .footer .btn {
    position: relative;
    border-radius: 4px;
    border: none;
    background-color: #32cc8e;
    padding: 10px 0px;
    text-decoration: none;
    transition: 0.3s;
    cursor: pointer;
  }
  .btn {
    opacity: 0;
    pointer-events: none;
  }
  .footer .btn:hover {
    background-color: #25c686;
  }
  .btn:first-child {
    background-color: #e14949;
  }
  .btn:first-child:hover {
    background-color: #f45353;
  }
  .modal_container {
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
  .modal_container .modal {
    width: var(--width-hint);
    height: 90%;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #080808;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 3;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    padding: 30px 50px;
    padding-bottom: 50px;
    border-radius: 10px;
    justify-content: flex-start;
  } 
  .modal .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px 5px 0px;
    position: absolute;
    bottom: 0%;
    left: 0%;
  } 
  .modal .head {
    color: #FFF;
    margin: 0;
    margin-bottom: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .hint_img {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 24rem;
  }
  .modal .content {
    width: 100%;
    overflow-x: hidden;
    max-height: 74vh;
    color: #080808;
    overflow-y: auto;
  }
  .head .title {
    color: #080808;
    margin-left: 5%;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
  }
  .content .page .desc span, .externals {
    font-size: 16px;
    line-height: 24px;
    opacity: .7;
    margin-left: 20px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .hideBtn {
    pointer-events: all;
    opacity: 1;
  }
  span.underline {
    margin-left: 0px!important;
    padding: 0;
    text-decoration: underline;
  }
  .externals {
    margin-left: 60px;
  }
  .externals.digital {
    list-style-type: decimal;
  }
  .externals.none {
    list-style-type: none;
  }
  .title.hide {
    display: none;
  }
  .page {
    height: 100%;
    overflow-y: auto;
  }
</style>
