<template>
    <div class="main_content">
        <ScreenshotModal
            ref='playerScreenshotModal'
            title="Снимок экрана" />
        <InputModal
            ref="setPlayerHeadlineModal"
            title="Установить бегущую строку"
            buttonName="Установить"
            @submit="updateHeadline"
            />
        <InputModal
            ref="setBroadcastHeadlineModal"
            title="Установить бегущую строку на всех экранах"
            buttonName="Установить"
            @submit="updateAllHeadlines"
            />
        <PlayerSetupWizard
            ref="playerWizardModal"
            @done="fetchAll"
            :devices="players" />
        <PreviewModal
            ref='previewPlayerModal'
            title='Предпросмотр'
            :files="filesById" />

        <Header>
            <button class="btn btn-primary" @click="openModal('playerWizardModal')">Мастер запуска плееров</button>
            <button class="btn" @click="editAllHeadlines">Установить строку на всех</button>
        </Header>
        <div class="content">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th><b>Имя</b></th>
                        <th><b>Плейлист</b></th>
                        <th><b>Бегущая строка</b></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(player, i) in activePlayers" :key="i">
                        <td class="list_icon">
                        </td>
                        <td class="replay">
                            <span>{{ player.name }}</span>
                        </td>
                        <td class="playlist">
                            <router-link
                                v-if="player.currentSchedule !== null"
                                style="color: #28d;"
                                :to="`/edit/${player.currentSchedule}`">
                                {{player.currentScheduleName}}
                            </router-link>
                            <br/>
                            <span v-if="player.nextSchedule !== null" class="number">
                                Устанавливается {{ player.nextScheduleName }}
                            </span>
                            <span style="color: red" v-if="player.lastError">
                                {{ getErrorDescription(player) }}
                            </span>
                        </td>
                        <td>
                            <span v-if="player.text">{{ player.text }}</span>
                            <br/>
                            <a @click="editHeadline(player)" class="edit">Изменить</a>
                        </td>
                        <td class="list-item-buttons">
                            <button @click="showScreenshot(player)" type="button" title="Снимок экрана"><img src="@/assets/imgs/playlist/camera.svg"></button>
                            <button
                                @click.stop="openModal('previewPlayerModal', player)"
                                title="Предпросмотр"
                            ><img src="/static/icons/preview.svg"></button>
                            <button @click="resetPlayer(player)" type="button" title="Stop"><img src="@/assets/imgs/playlist/delete.svg"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import CreateModal from '@/components/CreateModal.vue';
import SelectScheduleModal from '@/components/Devices/SelectScheduleModal.vue';
import PlayerSetupWizard from '@/components/Playing/PlayerSetupWizard.vue';
import InputModal from '@/components/InputModal.vue'
import PreviewModal from '@/components/Devices/PreviewModal.vue';
import ScreenshotModal from '@/components/Devices/ScreenshotModal.vue'
import ViewWithModalsMixin from '@/mixins/ViewWithModalsMixin';
import { APIError } from '@/api/wrappers';

export default {
    components: { Header, CreateModal, SelectScheduleModal, PlayerSetupWizard, ScreenshotModal, InputModal, PreviewModal },
    inject: ['playersAPI', 'filesAPI', 'messagePopup'],
    mixins: [ViewWithModalsMixin],
    name: 'Players',
    data: () => ({
        players: [],
        files: [],
        headlines: [],
        intervalData: null,
    }),
    mounted() {
        this.fetchPlayers();
        this.fetchHeadlines();
        this.fetchFiles();
        this.intervalData = setInterval(() => {
            this.fetchPlayers();
            this.fetchHeadlines();
        }, 5000);
    },
    unmounted() {
        clearInterval(this.intervalData)
    },
    computed: {
        headlinesById: function(){
            let result = {};
            this.headlines.forEach(function(headline) {
                result[headline.id] = headline;
            })
            return result;
        },
        activePlayers: function(){
            return this.players
                .filter((player) => {
                    return ((player.group != null) || (player.next != null)) && (player.status != 'offline');
                })
                .map((player) => ({
                    id: player.id,
                    name: player.name,
                    lastError: player.lastError,
                    currentSchedule: player.group,
                    currentScheduleName: player.scheduleName,
                    nextSchedule: player.next,
                    nextScheduleName: player.nextScheduleName,
                    status: player.status,
                    text: player.id in this.headlinesById ? this.headlinesById[player.id].text : null
                }));
        },
        filesById: function(){
            let result = {};
            this.files.forEach(function(file) {
                result[file.id] = file;
            })
            return result;
        },
    },
    methods: {
        fetchAll() {
            this.fetchHeadlines();
            this.fetchPlayers();
        },
        async fetchPlayers() {
            try {
                this.players = await this.playersAPI.fetch();
            } catch(e) {
                if(e instanceof APIError){
                    this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
                }
            }
        },
        async fetchHeadlines() {
            try {
                this.headlines = await this.playersAPI.getHeadlines();
            } catch(e) {
                if(e instanceof APIError){
                    this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
                }
            }
        },
        async fetchFiles() {
            try {
                this.files = await this.filesAPI.fetch();
            } catch(e) {
                if(e instanceof APIError){
                    this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
                }
            }
        },
        getErrorDescription(player) {
            if(
                (player.lastError.request !== undefined) &&
                (player.lastError.request !== null) &&
                (player.lastError.request.attributes !== null) &&
                (player.lastError.request.method === 'set_schedule')
            ){
                return 'Не удалось установить расписание: ' + player.lastError.request.scheduleName + ' (' + player.lastError.error + ')';
            } else {
                return 'Произошла ошибка: '+ player.lastError.error;
            }
        },
        showScreenshot(player) {
            this.openModal('playerScreenshotModal', player)
        },
        editHeadline(player) {
            this.openModal('setPlayerHeadlineModal', player, player.text)
        },
        editAllHeadlines() {
            this.openModal('setBroadcastHeadlineModal', null);
        },
        async updateHeadline(text, player){
            try {
                await this.playersAPI.updateHeadline(player.id, text);
                this.messagePopup.show('Бегущая строка установлена');
                this.fetchHeadlines();
            } catch(e) {
                if(e instanceof APIError){
                    this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
                }
            }
        },
        async updateAllHeadlines(text){
            try {
                await this.playersAPI.broadcastHeadline(text);
                this.messagePopup.show('Бегущая строка установлена');
                this.fetchHeadlines();
            } catch(e) {
                if(e instanceof APIError){
                    this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
                }
            }
        },
        async resetPlayer(player) {
            try {
                await this.playersAPI.reset(player.id);
                this.fetchPlayers();
            } catch(e) {
                if(e instanceof APIError){
                    this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
                }
            }
        },
    },
}

</script>
<style scoped>
  @media (max-width: 414px) {
        table tbody tr td {
            font-size: 12px !important;
        }
  }
    @media (max-width: 924px) {
        table td, table tr th {
            font-size: 14px;
        }
        table td .device span:last-child {
            font-size: 9px;
        }
        .replay {
            font-size: 12px;
        }
        .replay {
            margin-left: 10px;
        }
    }
    .main_content {
        width: 100%;
    }
    a.edit {
        color: #28d;
        text-decoration: underline;
        cursor: pointer;
    }
    .list_icon {
    width: 96px;
    align-self: stretch;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 48px;	
    background-image: url("@/assets/imgs/playlist/monitor.svg");
    }
    .main_content .content {
        width: 100%;
        padding: 6px;
        overflow-y: auto;
    }
    .content table {
    width: 100%;
    border-collapse: collapse;
    }
    td.playlist {
        vertical-align: middle;
    }
    table th {
        font-weight: 400;
        font-size: 18px;
        padding: 0 10px 10px 0px;
        text-align: center;
    }
    table thead tr th {
    padding: 10px;
    vertical-align: middle;
    text-align: left;
        font-size: 14px;
        white-space: nowrap;
    border-bottom: 2px solid #CCC;
        color: #000;
    }
    table tbody tr{
    align-items: center;
    max-height: 67px;
        height: 67px;
    }
    table tbody tr td {
        text-align: left;
        padding: 15px 10px;
        padding-left: 0;
        font-size: 15px;
    max-height: 67px;
    }
    .replay {
        font-weight: bold;
        font-size: 14px;
        margin-left: 15px;
    }
    .device {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: nowrap;
    }
    .device.active span {
        opacity: 1;
        color: #000914;
    }
    .device span {
        font-weight: bold;
        opacity: 0.5;
    }
    .device.active img {
        opacity: 1;
    }
    .device img {
        opacity: 0.5;
    }
    .device .title {
        margin:0px 10px;
    }
    .device.active .number {
        opacity: .7;
    }
    .device .number {
        opacity: .3;
        font-size: 12px;
        color: #898c8f;
        font-weight: 300;
    }
    .list-item-buttons {
        display: flex;
    }
</style>
