// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/playlist/warning-modal.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/imgs/playlist/delete-modal.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ds-dialog-fog[data-v-179182f6]{z-index:3;position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,.75);display:flex;align-items:center;justify-content:center;overflow-y:hidden}.ds-minidialog[data-v-179182f6]{max-width:480px;width:100%;max-height:90%;overflow:hidden;background:#efefef;border:1px solid #080808;box-sizing:border-box;box-shadow:0 0 8px rgba(0,0,0,.3);z-index:3;display:flex;flex-direction:column}.dialog-header[data-v-179182f6]{background:#555;color:#fff;margin:0;padding:10px;font-size:1.3em;font-weight:700}.ds-minidialog>.dialog-body[data-v-179182f6]{overflow:auto}.dialog-body.dialog-message[data-v-179182f6]{padding:15px;font-size:110%;background-color:#fff}.dialog-warning[data-v-179182f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.dialog-delete[data-v-179182f6],.dialog-warning[data-v-179182f6]{background-repeat:no-repeat;background-position:left 18px center;background-size:64px;padding-left:100px!important;min-height:80px}.dialog-delete[data-v-179182f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.dialog-footer[data-v-179182f6]{margin:0 5px;padding:10px;text-align:right;border-top:1px solid #ccc}.btn[data-v-179182f6]:first-child{margin-right:5px}.btn[data-v-179182f6]{font:400 10pt Golos Text,Open Sans,Segoe UI,Liberation Sans,sans-serif;background:#888;color:#fff;border:0;border-radius:3px;padding:5px 10px;vertical-align:bottom;min-width:120px;cursor:pointer;display:inline-block;text-decoration:none;transition:all .3s ease-in-out}.dialog-footer .btn[data-v-179182f6]:hover{background:#737373}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
