<template>
<div @click="close" class="ds-dialog-fog" v-if="visible">

    <div @click.stop class="ds-minidialog">
        <div class="dialog-header">
          <div class="toolbarheader">Статус медиаплеера {{ status.name }}</div>
        </div>
        <div class="dialog-body">
          <div class="microdialog_section">
            Идентификатор
          </div>
          <div class="microdialog_row" id="playerSummaryId">
            {{ status.id }}
          </div>
          <div class="microdialog_section">Примененное расписание</div>
          <div class="microdialog_row" style="display: flex; align-items: center;">
            {{ scheduleName }} {{ status.next_schedule_id !== null ? '(устанавливается ' + nextScheduleName + ')' : '' }}
            <button class="btn" style="margin-left: auto" @click="selectSchedule">Выбрать расписание</button>
          </div>
          
          <div class="microdialog_section">
            Последний запрос
          </div>
          <div class="microdialog_row">
            {{ lastRequestAgo }} ({{ lastRequestTime }})
          </div>
          
          <div class="microdialog_section">
            IP-адрес медиаплеера
          </div>
          <div class="microdialog_row" id="playerSummaryAddress">
            {{ status.stats ? status.stats.ip_address : '0.0.0.0' }}
          </div>
          
          <div class="microdialog_section">
            Выполняемая задача
          </div>
          <div class="microdialog_row" id="playerSummaryActivity">
            {{ status.stats ? status.stats.process_executable: 'n/a'}} <span v-if="status.stats && status.stats.process_status">– {{ resourcePrettyName }}</span>
          </div>
          <div class="microdialog_section">
            Бегущая строка
          </div>
          <div class="microdialog_row" style="display: flex; align-items: center;">
            <input type="text" v-model="headline" placeholder="Введите бегущую строку" id="playerSummaryHeadline" style="margin-right: 1em;">
            <button class="btn" @click="updateSub">Установить</button>
          </div>
          
          <div class="microdialog_section">Уровень громкости проигрывания</div>
          <div class="microdialog_row">
            <input type="range" v-model="volume" min="0" max="100"/>
          </div>
        </div>
        <div class="dialog-footer">
          <div style="float:left">
            <button class="btn" @click="openDetail">Подробнее</button>
          </div>
          <div>
            <button class="btn" @click="saveVolume">Сохранить</button>
            <button class="btn btn-close" @click="close">Закрыть</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import StatefulModalMixin from '@/mixins/StatefulModal'
import {DateFormatter, DateDifference} from "@/utils/formatters"

export default {
  name: 'StatusModal',
  mixins: [StatefulModalMixin],
  inject: ['playersAPI'],
  props: {
    headlines: Object,
    schedules: Object,
    files: Object
  },
  data: () => ({
    status: {},
    headline: '',
    volume: 0,
    updateStatus: null
  }),
  methods: {
    async fetchPlayerInfo(updateInputs){
      this.status = await this.playersAPI.status(this.state.id);
      if(updateInputs) {
        let subcontrols = await this.playersAPI.getParameters(this.state.id);
        this.volume = subcontrols.volume;
        this.headline = this.headlines[this.state.id].text;
      }
      this.updateStatus = setTimeout(this.fetchPlayerInfo.bind(this), 5000);
    },
    openDetail() {
      this.$router.push(`/players/${this.state.id}`)
    },
		async updateSub() {
      await this.playersAPI.updateHeadline(this.state.id, this.headline);
      this.$emit('headline-updated');
    },
    selectSchedule() {
      this.$emit('select-schedule', this.state);
    },
    async saveVolume() {
      await this.playersAPI.setVolume(this.state.id, parseInt(this.volume));
    },
  },
  computed: {
    lastRequestTime: function(){
      return DateFormatter(this.status.lastacc)
    },
    lastRequestAgo: function(){
      return DateDifference(this.status.lastacc)
    },
    scheduleName: function(){
      // TODO: handle edge cases
      if(this.status.schedule_id === undefined) return null;
      if(!(this.status.schedule_id in this.schedules)) return this.status.schedule_id;
      return this.schedules[this.status.schedule_id].name;
    },
    nextScheduleName: function(){
      // TODO: handle edge cases
      if(this.status.next_schedule_id === undefined) return null;
      if(!(this.status.next_schedule_id in this.schedules)) return this.status.next_schedule_id;
      return this.schedules[this.status.next_schedule_id].name;
    },
    resourcePrettyName: function(){
      if((this.status.stats === undefined)) return null;
      if(['play', 'slideshow'].includes(this.status.stats.process_executable)){
        if(!(this.status.stats.process_status in this.files))
          return this.status.stats.process_status;
        return this.files[this.status.stats.process_status].name;
      } else {
        return this.status.stats.process_status;
      }
    },
    resourcePreviewUrl: function(){
      if((this.status.stats === undefined)) return null;
      if(['play', 'slideshow'].includes(this.status.stats.process_executable)){
        return '/thumb/' + this.status.stats.process_status + '.jpg';
      } else {
        return '/thumb/default.jpg';
      }
    },
  },
  watch: {
    visible: function(isOpened){
      if(isOpened) {
        this.fetchPlayerInfo(true);
      } else {
        clearTimeout(this.updateStatus);
      }
    }
  }
}
</script>
<style scoped>  
.ds-dialog-fog {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}
.ds-minidialog {
  max-width: 480px;
  width: 100%;
  max-height: 90%;
  overflow: hidden;
  background: #EFEFEF;
  border: 1px solid #080808;
  box-sizing: border-box;
  box-shadow: 0 0 8px rgb(0 0 0 / 30%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.dialog-header {
  background: #555;
  color: #FFF;
  margin: 0;
  padding: 10px;
  font-size: 1.3em;
  font-weight: bold;
}

.ds-minidialog > .dialog-body {
  overflow: auto;
  padding: 5px;
}
.dialog-footer {
  margin: 0 5px;
  padding: 10px;
  text-align: right;
  border-top: 1px solid #CCC;
}
.microdialog_section {
  padding: 10px 5px;
  font-weight: bold;
}
.microdialog_row {
  padding: 0px 10px 10px;
}
.btn {
  font: 400 10pt "Golos Text", "Open Sans", "Segoe UI", "Liberation Sans", sans-serif;
  background: #888;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 5px 10px;
  vertical-align: bottom;
  min-width: 120px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
.btn-close {
  margin-left: 5px;
}
.dialog-body input[type=text], .dialog-body input[type=password], .dialog-body select {
  display: block;
  padding: 6px;
  margin: 6px 0;
  width: 100%;
  box-sizing: border-box;
  background: #FFF;
  border: 1px solid #909090;
}
</style>
