<template>
	<div class="layout-sidebar" :class="{'layout-sidebar-collapsed': toggled}">
		<div class="sidebar-header">
			<b>DIGITAL SIGNAGE SERVER</b>
      <button id="sidebarSwitch" @click="toggleSidebar">☰</button>
		</div>
    <div class="sidebar-menu">
      <div class="sidebar-item"
        @click="goRoute('Players')"
        :class="{'active': $route.name === 'Players', 'disabled': $route.name === 'Login'}"
      >
        <img src="@/assets/imgs/sidebar/menuplaceholder.svg">
        <div>Воспроизведение</div>
      </div>
      <div class="sidebar-item"
        @click="goRoute('Devices')"
        :class="{'active': $route.name === 'Devices' || $route.name === 'Player', 'disabled': $route.name === 'Login'}"
      >
        <img src="@/assets/imgs/sidebar/menuplaceholder.svg">
        <div>Устройства</div>
      </div>
      <div class="sidebar-item"
        @click="goRoute('Playlists')"
        :class="{'active': $route.name === 'Playlists' || $route.name === 'ListEdit', 'disabled': $route.name === 'Login'}"
      >
        <img src="@/assets/imgs/sidebar/menuplaceholder.svg">
        <div>Плейлисты</div>
      </div>
      <div class="sidebar-item"
        @click="goRoute('Files')"
        :class="{'active': $route.name === 'Files' || $route.name === 'Directory', 'disabled': $route.name === 'Login'}"
      >
        <img src="@/assets/imgs/sidebar/menuplaceholder.svg">
        <div>Материалы</div>
      </div>
    </div>
    <div class="sidebar-login-block" v-if="loggedUser">
      Выполнен вход как {{username}} <a class="cursor" @click="logout">Выйти</a>
    </div>
    <div class="sidebar-login-block" v-else>
      <router-link :to="{name: 'Login', query: {login: 'true'} }">Войти</router-link>
    </div>
    <div class="sidebar-footer" v-if="loggedUser">
      <div class='sidebar-gauge'>
        <div class='sidebar-gauge-filled'></div>
      </div>
      <div class='sidebar-gauge-captions'>
        <span>Материалы: {{this.media_space}}</span>
        <span>Свободно: {{total_space}}</span>
      </div>
    </div>
	</div>
</template>

<script>
import { eraseCookie, formatBytes, getCookie } from '@/api/func'
export default {
  name: 'Sidebar',
  inject: ['authorizationAPI', 'authorizationManager'],
  data: () => ({
    total_space: 0,
    media_space: 0,
    loggedUser: false,
    toggled: false,
    username: "",
    routeName: ""
  }),
  props: {
    isedit: Boolean,
  },
  mounted() {
    console.log('isedit', this.isedit)
    this.getUser()
    this.getSpace()
  },
  methods: {
    goRoute(name) {
      console.log('isedit', this.isedit)
      if(this.isedit) {
        this.routeName = name
        this.openWarning = true
        return
      }
      this.$router.push({name: name})
    },
    getUser() {
      this.username = getCookie('user')
      let session_id = getCookie('session_id')
      if(session_id && this.username) {
        this.loggedUser = true
        return
      }
      this.$router.push({name: "Login"})
    },
    toggleSidebar() {
      this.toggled = !this.toggled
    },
    async logout() {
      await this.authorizationAPI.logout()
      eraseCookie('session_id')
      eraseCookie('user')
      this.loggedUser = false
      this.authorizationManager.setLoggedOut();
      this.$router.push({name: 'Login'})
    },
    async getSpace() {
      await fetch("/api/files/usage").then(async res => {
        let lists = await res.json()
        this.total_space = formatBytes(lists.media + lists.free)
        this.media_space = formatBytes(lists.media)
        let root = document.querySelector(':root');
        let spaced = parseInt((lists.media / (lists.media + lists.free)) * 100);
        root.style.setProperty('--spaced', spaced+'%');

      })
    }
  },
  watch:{
    $route (){
      this.getUser()
    },
    isedit() {
      console.log(this.isedit)
    }
  } 
}
</script>
<style scoped>
  :root {
    --spaced: 40%;
  }
  .sidebar-login-block a {
    color:#28d;
  }
  .sidebar-gauge-captions {
    width: 100%;
    display: flex;
    height: 24px;
    color: #000;
    font-family: inherit;
    font-size: 8pt;
    font-weight: bold;
    margin-top: -24px;
  }
  .sidebar-gauge-captions span {
    margin: auto;
  }
  .sidebar-gauge {
    width: 100%;
    border: 1px solid #000;
    background: #fff;
    display: flex;
    height: 24px;
  }
  .sidebar-gauge-filled {
    width: var(--spaced);
    background: #08f;
  }
  /*
  #sidebarSwitch {
    font-size: 18pt;
    vertical-align: middle;
    margin-top: -5px;
    margin-left: auto;
    border: 0;
    background: transparent;
    cursor: pointer;
    color: #FFF;
  }
  */

  .disk_avalible {
    background-color: white;
    text-align: center;
    padding: 0px 5px;
    height: 20px;
    font-size: 11px;
    font-weight: bold;
    width: 90%;
    color: #000914;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .disk_avalible::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #30a5e8;
    width: var(--spaced);
    z-index: 3;
  }
</style>
