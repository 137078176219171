<template>
	<div class="main_content">
    <UploadFile
      ref="uploadModal"
      title="Загрузка материалов"
      :loading="isLoading"
      :folder="currentFolder"
      @savefile="saveFile" />
    
    <PreviewFile
      ref='previewFileModal'
      title="Просмотр" />
    <MoveFileModal
      ref="moveFileModal"
      :folders="folders"
      @submit="moveToFolder" />
    <ConfirmModal
      ref="deleteSelectedModal"
      title="Удалить"
      text="Вы хотите удалить выбранные файлы"
      okText="Удалить"
      type="delete"
      @confirm="deleteSelected" />
    <ConfirmModal
      ref="deleteModal"
      title="Удалить"
      text="Вы хотите удалить файл: "
      okText="Удалить"
      type="delete"
      @confirm="deleteItem" />
    <InputModal
      ref="createFolderModal"
      title="Введите имя для новой папки"
      buttonName="Создать"
      @submit="createDir" />
    <InputModal
      ref="renameFolderModal"
      title="Введите новое имя для папки"
      buttonName="Переименовать"
      @submit="renameFolder" />
    <InputModal
      ref="renameModal"
      title="Введите новое имя для файла"
      buttonName="Переименовать"
      @submit="renameFile" />

    <Header>
      <button class="btn btn-primary" @click="openModal('uploadModal', null)">Загрузить</button>
      <button v-if="currentFolder == null" class="btn" @click="openModal('createFolderModal', null)">Создать папку</button>
    </Header>
    <div v-if="convertingFiles.length > 0" class="statusbar">
      <span id="conversionProgress">Обработано файлов - 
        <div v-for="item in convertingFiles" :key="item[0]" style="display: flex">
          {{ item[0] }}
          <progress style="margin-left: auto" max="100" :value="item[1]">{{item[1]}}</progress>
        </div>
      </span>
    </div>
    <div>
        <!-- Folders -->
        <div
          class="list-item"
          @click="goUp()"
          v-if="currentFolder !== null"
          @drop="onDrop($event, null)"
          @dragover.prevent
          @dragenter.prevent>
          <div class="list-item-checkbox" style="visibility: hidden">
            <input type="checkbox">
          </div>
          <div class="list-item-icon" style="cursor: pointer; background-image: url('/static/icons/folder-up.svg')"></div>
          <div class="list-item-caption" style="cursor: pointer;">
            <b>Назад</b>
          </div>
        </div>
        <div class="list-item" 
            @click="openFolder(folder.id)"
            @drop="onDrop($event, folder.id)"
            @dragover.prevent
            @dragenter.prevent
            v-for="folder in folders" :key="folder.id">
          <div class="list-item-checkbox" style="visibility: hidden">
            <input type="checkbox">
          </div>
          <div class="list-item-icon" style="cursor: pointer; background-image: url('/static/icons/folder.svg')"></div>
          <div class="list-item-caption" style="cursor: pointer;">
            <b>{{ folder.name }}</b>
          </div>
          <div class="list-item-buttons">
            <button
              @click.stop="openModal('renameFolderModal', folder, folder.name)"
              title="Переименовать"
            ><img src="@/assets/imgs/playlist/rename.svg"></button>
            <button
              @click.stop="openModal('deleteModal', folder)"
              title="Удалить"
            ><img src="@/assets/imgs/playlist/delete.svg"/></button>
          </div>
        </div>
        <!-- Items -->
        <div 
          v-for="item in files" 
          :key="item.id"
          @dragstart="onDragStart($event, item)" 
          draggable="true" 
          class="list-item"
        >
          <div class="list-item-checkbox" @dragstart.stop>
            <input type="checkbox" v-model="selectedFiles" :value="item.id">
          </div>
          <div @click="openModal('previewFileModal', item)" class="list-item-icon" style="cursor: pointer;" :style="{'background-image': item.thumbnail}"></div>
          <div @click="openModal('previewFileModal', item)" class="list-item-caption" style="cursor: pointer;">
            <b>{{item.name}}</b>
            <div v-if="item.error != null">Ошибка при обработке видео</div>
            <div v-else-if="item.size">Размер: {{item.size}}</div>
          </div>
          <div class="list-item-buttons">
            <button
              v-if="item.error == null"
              @click.stop="openModal('previewFileModal', item)"
              title="Просмотр"
            ><img src="@/assets/imgs/stuff/preview.svg"></button>
            <button
              v-if="item.error == null"
              @click.stop="openModal('renameModal', item, item.name)"
              title="Переименовать"
            ><img src="@/assets/imgs/playlist/rename.svg"></button>
            <a
              v-if="item.error == null"
              :href="item.downloadPath"
              :download="item.name"
              class="download"
              title="Скачать"
            ><img src="@/assets/imgs/stuff/download.svg"/></a>
            <button
              @click.stop="openModal('deleteModal', item)"
              title="Удалить"
            ><img src="@/assets/imgs/playlist/delete.svg"/></button>
          </div>
        </div>
		</div>
    <div class="btns" v-if="selectedFiles.length > 0">
      <button @click="openModal('moveFileModal', null)" class="btn">Переместить</button>
      <button @click="openModal('deleteSelectedModal', null)" class="btn">Удалить</button>
    </div>
	</div>
</template>
<script>
import Header from "@/components/Header.vue"
import UploadFile from '@/components/UploadFile.vue'
import InputModal from '@/components/InputModal.vue'
import MoveFileModal from '@/components/Stuff/MoveFileModal.vue'
import PreviewFile from '@/components/Stuff/PreviewFile.vue'
import {formatBytes} from "@/api/playlist/func"
import ConfirmModal from '@/components/ConfirmModal.vue'
import ViewWithModalsMixin from "@/mixins/ViewWithModalsMixin"

const documentRegex = new RegExp('\.(pdf|doc|docx|odt)', 'i');
const spreadsheetRegex = new RegExp('\.(xls|xlsx|ods)', 'i');
const presentationRegex = new RegExp('\.(ppt|pptx|pps|ppsx|odp)', 'i');

export default {
  name: 'Files',
  inject: ['filesAPI', 'messagePopup'],
  mixins: [ViewWithModalsMixin],
  data: () => ({
    folderContents: [],
    convertingFiles: [],
    selectedFiles: [],

    nameChange: "",
    clickedItem: {},
    intervalStatus: null,
    openCreateFile: false,
    isLoading: false,
    text_warning: null,
  }),
  components: {
    Header,
    UploadFile,
    InputModal,
    MoveFileModal,
    PreviewFile,
    ConfirmModal
  },
  mounted() {
    this.fetchFiles(this.currentFolder);
    this.fetchConvertingFiles();
    this.$watch(() => this.$route.params, () => {
      this.folderContents = [];
      this.selectedFiles = [];
      this.fetchFiles(this.currentFolder);
    });
  },
  computed: {
    files() {
      return this.folderContents.filter(f => {
        return !f.isFolder
      }).map((f) => {
        let image = '';
        if(f.error == 1){
          image = '/static/icons/broken.svg';
        } else if(f.isFolder){
				  image = '/static/icons/folder.svg';
		    } else if(documentRegex.test(f.name)){
          image = '/static/document-generic-64.png';
		    } else if(spreadsheetRegex.test(f.name)){
          image = '/static/document-spreadsheet-64.png';
        } else if(presentationRegex.test(f.name)){
          image = '/static/document-presentation-64.png';
		    } else {
    			image = '/thumb/' + encodeURIComponent(f.id) + '.jpg';
    		}
        return {
          id: f.id,
          isFolder: f.isFolder,
          error: f.error,
          name: f.name,
          type: f.filetype,
          thumbnail: 'url(' + image + ')',
          downloadPath: '/media/' + f.id,
          size: formatBytes(f.size)
        }
      })
    },
    folders() {
      return this.folderContents.filter(f => {
        return f.isFolder;
      })
    },
    currentFolder() {
      if('id' in this.$route.params){
        return this.$route.params.id;
      } else {
        return null;
      }
    }
  },
  methods: {
    async fetchFiles(id) {
      this.folderContents = await this.filesAPI.fetchFolder(id);
    },
    async fetchConvertingFiles() {
      this.convertingFiles = await this.filesAPI.convertingFiles();
      if(this.convertingFiles.length > 0){
        setTimeout(this.fetchConvertingFiles.bind(this), 1000);
      } else {
        this.fetchFiles(this.currentFolder);
      }
    },
    goUp() {
      this.$router.push('/files');
    },
    async deleteSelected() {
      if(this.selectedFiles.length < 1){
        this.messagePopup.show('Не выбраны файлы для удаления');
      }
      try {
        await this.filesAPI.removeMultiple(this.selectedFiles);
      } catch(e) {
        this.messagePopup.show('Не удалось удалить файлы');
      } finally {
        this.selectedFiles = [];
        this.fetchFiles(this.currentFolder);
      }
    },
    async deleteItem(item){
      try {
        if(item.isFolder){
          await this.filesAPI.removeFolder(item.id);
        } else {
          await this.filesAPI.remove(item.id);
        }
      } catch(e) {
        this.messagePopup.show('Не удалось удалить ' + item.name);
      } finally {
        this.fetchFiles(this.currentFolder);
      }
    },
    onDrop(e, folderId) {
      const itemId = e.dataTransfer.getData('itemId')
      this.moveToFolder(folderId, [itemId])
    },
    onDragStart(e, item) {
      e.dataTransfer.dropEffect = 'move'
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.setData('itemId', item.id.toString())
    },
    async moveToFolder(folder, items) {
      console.log(folder, items);
      if(items === undefined){
        items = this.selectedFiles.slice();
        this.selectedFiles = [];
      }
      try {
        await this.filesAPI.moveFiles(folder, items)
      } catch(e) {
        this.messagePopup.show("Не удалось переместить файлы в папку")
      } finally {
        this.fetchFiles(this.currentFolder);
        this.openMoveDir = false;
      }
    },
    async createDir(name) {
      try {
        await this.filesAPI.createFolder(name);
        this.messagePopup.show("Папка " + name + " создана");
      } catch(e) {
        this.messagePopup.show("Ошибка при создании папки")
      } finally {
        this.fetchFiles(this.currentFolder);
      }
    },
    async renameFile(name, file) {
      try {
        await this.filesAPI.rename(file.id, name);
        this.messagePopup.show("Папка " + name + " создана");
      } catch(e) {
        this.messagePopup.show("Ошибка при создании папки")
      } finally {
        this.fetchFiles(this.currentFolder);
      }
    },
    async renameFolder(name, file) {
      try {
        await this.filesAPI.renameFolder(file.id, name);
        this.messagePopup.show("Папка " + name + " создана");
      } catch(e) {
        this.messagePopup.show("Ошибка при создании папки")
      } finally {
        this.fetchFiles(this.currentFolder);
      }
    },
    openFolder(id) {
      this.$router.push(`/files/${id}`)
    },
    async saveFile(files, folder) {
      this.isLoading = true
      try {
        this.isLoading = true;
        await this.filesAPI.upload(files, folder)
        this.messagePopup.show("Файл успешно загружен")
      } catch(e) {
        this.messagePopup.show("Не удалось загрузить файл")
      } finally {
        this.isLoading = false;
        this.closeModal('uploadModal');
        this.fetchConvertingFiles();
        this.fetchFiles(this.currentFolder);
      }
    },
  },
}
</script>
<style scoped>

@media (max-width: 414px) {
  .list-item-caption b {
    font-size: 12px;
  }
}
.ds-selected {
  background: red !important;
}
	.main_content {
		width: 100%;
    height: 100%;
    overflow: auto;
	}

	.main_content .content {
    width: 100%;
    /* height: calc(100% - 99px); */
    position: relative;
    padding: 0;
	}
  .img_container {
    width: 100%;
    padding: 6px;
  }
  .list-item-checkbox {
    margin: 0px 4px;
  }
  .list-item-icon {
    width: 96px;
    height: 56px;
    align-self: stretch;
    display: flex;
    justify-content: center;
  }
  .list-item-icon img {
    min-width: 55px;
    width: 90%;
    height: 90%;
  }
  .list-item-caption {
    flex-grow: 1;
    padding: 10px;
    min-height: 56px;
    font-size: 14px;
  }
  .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 5px;
    box-sizing: border-box;
    border-bottom: 1px solid #DDD;
  }
  .imgs img, .folder {
    width: 250px;
    height: 150px;
    margin: 0px 8px;
    object-fit: cover;
    vertical-align: baseline;
  }
  .btn:nth-child(2) {
    margin:0px 5px;
  }
  .btns {
    background-color: #AAA;
    position: fixed;
    bottom: 0;
    width: 100%;
    color: #000;
    padding: 8px 8px;
    border-bottom: 1px solid #CCC;
  }
  .list-item-buttons {
    display: flex;
  }
  .download {
    width: 34px;
  }
.statusbar {
    padding: 8px 12px;
    background-color: #BCD;
}
</style>
