export default {
    methods: {
        openModal(modalName, subject, defaultValue) {
            if(!(modalName in this.$refs)){
                console.error('Unknown modal: ' + modalName);
            } else {
                this.$refs[modalName].open(subject, defaultValue);
            }
        },
        closeModal(modalName) {
            if(!(modalName in this.$refs)){
                console.error('Unknown modal: ' + modalName);
            } else {
                this.$refs[modalName].close();
            }
        },
    }
}