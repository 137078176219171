export class Mediafile {
    constructor(id, type, isFolder) {
        this.id = id;
        this.type = type;
        this.isFolder = isFolder;
    }
    copy() {
        return new Mediafile(this.id, this.type, this.isFolder);
    }
    calculateSize(files) {
        return files.reduce((a, f) => {
            if(!this.isFolder && (f.id == this.id)) {
                return a + 1;
            } else if(this.isFolder && (f.folder == this.id) && (f.filetype == this.type)) {
                return a + 1;
            }
            return a;
        }, 0)
    }
    export(){
        return {
            id: this.id,
            folder: this.isFolder
        }
    }
}

export class DailyTime {
    constructor(hour, minutes) {
        this.label = 'Каждый день';
        this.hour = hour;
        this.minutes = minutes;
    }

    copy() {
        return new DailyTime(this.hour, this.minutes);
    }

    toString() {
        return (this.hour < 10 ? '0' + this.hour : this.hour) + ':' + (this.minutes < 10 ? '0' + this.minutes : this.minutes)
    }

    export(){
        return {
            hour: this.hour.toString(),
            minute: this.minutes.toString()
        }
    }
}

export class HourlyTime {
    constructor(minutes) {
        this.label = 'Каждый час';
        this.minutes = minutes;
    }

    copy(){
        return new HourlyTime(this.minutes);
    }

    toString() {
        return this.minutes.toString();
    }

    export(){
        return {
            hour: '*',
            minute: this.minutes.toString()
        }
    }
}

export class ScheduleBehavior {

}

export class DefaultSchedule extends ScheduleBehavior {
    constructor() {
        super()
        this.label = 'По умолчанию';
    }

    copy() {
        return new DefaultSchedule();
    }

    toString() {
        return 'Задача по умолчанию';
    }
    
    export(){
        return null;
    }
}

export class PlayUntilCompleted extends ScheduleBehavior {
    constructor(start) {
        super()
        this.label = 'Проиграть до конца';
        this.start = start;
    }

    copy() {
        return new PlayUntilCompleted(this.start.copy());
    }

    toString(){
        if(this.start instanceof DailyTime){
            return `В ${this.start.toString()}`
        } else if(this.start instanceof HourlyTime) {
            return `В ${this.start.toString()} минут каждый час`
        }
    }

    export(){
        return [this.start.export()]
    }
}

export class PlayUntilTime extends ScheduleBehavior {
    constructor(start, end) {
        super()
        this.label = 'Повторять';
        this.start = start;
        this.end = end;
    }

    copy() {
        return new PlayUntilTime(this.start.copy(), this.end.copy());
    }

    toString(){
        if(this.start instanceof DailyTime){
            return `С ${this.start.toString()} до ${this.end.toString()}`
        } else if(this.start instanceof HourlyTime) {
            return `С ${this.start.toString()} по ${this.end.toString()} минуту каждый час`
        }
    }

    export(){
        return [this.start.export(), this.end.export()]
    }
}

export class Task {
    constructor(taskSchedule) {
        this.schedule = taskSchedule;
    }
}

export class SleepTask extends Task {
    constructor(schedule) {
        super(schedule)
        this.label = 'Спящий режим';
    }
    copy() {
        return new SleepTask(this.schedule.copy());
    }
    export(){
        return {
            method: 'sleep',
            resource: null,
            schedule: this.schedule.export()
        }
    }
}

export class PlaylistTask extends Task {
    constructor(playlist, schedule) {
        super(schedule);
        this.playlist = playlist;
    }
    calculateSize(files) {
        return this.playlist.reduce((sum, item) => {
            return sum + item.calculateSize(files);
        }, 0);
    }
    add(file) {
        this.playlist.push(file);
    }
    remove(file) {
        this.playlist = this.playlist.filter((f) => {
            return f.id != file.id;
        });
    }
    insertBeforeIndex(file, index) {
        this.playlist.splice(index, 0, file);
    }
    move(oldIndex, newIndex) {
        // TODO: check bounds
        let file = this.playlist.splice(oldIndex, 1)[0];
        if(newIndex > oldIndex) newIndex - 1;
        this.playlist.splice(newIndex, 0, file);
    }
    moveUp(index) {
        if((index < 1) && (index >= this.playlist.length))
            throw Error('Unreachable playlist item');
        let t = this.playlist[index];
        this.playlist[index] = this.playlist[index - 1];
        this.playlist[index - 1] = t;
    }
    moveDown(index) {
        if((index < 0) && (index >= this.playlist.length - 1))
            throw Error('Unreachable playlist item');
        let t = this.playlist[index];
        this.playlist[index] = this.playlist[index + 1];
        this.playlist[index + 1] = t;
    }
    removeByIndex(index) {
        if((index < 0) && (index >= this.playlist.length))
            throw Error('Unreachable playlist item');
        this.playlist.splice(index, 1);
    }
}

export class URLTask extends Task {
    constructor(url, schedule) {
        super(schedule);
        this.url = url;
    }
}

export class VideoTask extends PlaylistTask {
    constructor(playlist, schedule) {
        super(playlist, schedule);
        this.acceptedType = 'video';
        this.label = 'Видео';
    }
    copy(){
        return new VideoTask(
            this.playlist.map(it => it.copy()),
            this.schedule.copy()
        );
    }
    export(){
        return {
            method: 'play',
            resource: this.playlist.map((f) => {
                return f.export()
            }),
            schedule: this.schedule.export()
        }
    }
}

export class DocumentTask extends PlaylistTask {
    constructor(playlist, schedule) {
        super(playlist, schedule);
        this.acceptedType = 'document';
        this.label = 'Документы';
    }
    copy(){
        return new DocumentTask(
            this.playlist.map(it => it.copy()),
            this.schedule.copy()
        );
    }
    export(){
        return {
            method: 'doc',
            resource: this.playlist.map((f) => {
                return f.export()
            }),
            schedule: this.schedule.export()
        }
    }
}

export class SlideshowTask extends PlaylistTask {
    constructor(videos, schedule, delay) {
        super(videos, schedule);
        this.label = 'Изображения';
        this.acceptedType = 'image';
        this.delay = delay;
    }
    copy(){
        return new SlideshowTask(
            this.playlist.map(it => it.copy()),
            this.schedule.copy(),
            this.delay
        );
    }
    export(){
        return {
            method: 'slideshow',
            delay: this.delay,
            resource: this.playlist.map((f) => {
                return f.export()
            }),
            schedule: this.schedule.export()
        }
    }
}

export class StreamTask extends URLTask {
    constructor(url, schedule) {
        super(url, schedule);
        this.label = 'Трансляция';
    }
    copy() {
        return new StreamTask(this.url, this.schedule.copy());
    }
    export(){
        return {
            method: 'stream',
            resource: this.url,
            schedule: this.schedule.export()
        }
    }
}

export class WebTask extends URLTask {
    constructor(url, schedule) {
        super(url, schedule);
        this.label = 'Web-страница';
    }
    copy() {
        return new WebTask(this.url, this.schedule.copy());
    }
    export(){
        return {
            method: 'web',
            resource: this.url,
            schedule: this.schedule.export()
        }
    }
}

export class Schedule {
    constructor() {
        this.tasks = [];
        this.hasDefaultTask = false;
    }
    addTask(task) {
        if(task.schedule instanceof DefaultSchedule){
            if(this.hasDefaultTask)
                throw Error('Schedule has default task already');
            this.hasDefaultTask = true;
        }
        this.tasks.push(task);
        return this.tasks.length - 1;
    }
    updateTask(index, task) {
        const wasDefault = this.tasks[index].schedule instanceof DefaultSchedule
        if(task.schedule instanceof DefaultSchedule){
            if(this.hasDefaultTask && !wasDefault)
                throw Error('Schedule has default task already');
            this.hasDefaultTask = true;
        } else if(wasDefault) {
            this.hasDefaultTask = false;
        }
        this.tasks[index] = task;
    }
    removeTask(task) {
        if(task.schedule instanceof DefaultSchedule){
            this.hasDefaultTask = false;
        }
        this.tasks = this.tasks.filter((t) => { return t != task });
    }
    export() {
        return this.tasks.map((t) => { return t.export(); })
    }
    parseTime(time) {
        if(time.hour == '*'){
            return new HourlyTime(parseInt(time.minute))
        } else {
            return new DailyTime(parseInt(time.hour), parseInt(time.minute))
        }
    }
    parseTaskSchedule(schedule) {
        if(schedule == null){
            return new DefaultSchedule();
        } else if(schedule.length == 1){
            return new PlayUntilCompleted(
                this.parseTime(schedule[0])
            )
        } else if(schedule.length == 2){
            return new PlayUntilTime(
                this.parseTime(schedule[0]),
                this.parseTime(schedule[1])
            )
        }
    }
    copy() {
        let schedule = new Schedule();
        schedule.hasDefaultTask = this.hasDefaultTask;
        schedule.tasks = this.tasks.map(it => it.copy());
        return schedule;
    }
    import(tasks){
        let parsedTasks = tasks.map((t) => {
            let schedule = this.parseTaskSchedule(t.schedule);
            if(schedule instanceof DefaultSchedule)
                this.hasDefaultTask = true;
            // TODO: inject mediafile data from files
            // TODO: add type to mediafiles/folders
            switch(t.method){
                case 'play':
                    return new VideoTask(
                        t.resource.map((r) => {
                            return new Mediafile(r.id, 'video', r.folder)
                        }),
                        schedule
                    )
                case 'doc':
                    return new DocumentTask(
                        t.resource.map((r) => {
                            return new Mediafile(r.id, 'document', r.folder)
                        }),
                        schedule
                    )
                case 'slideshow':
                    return new SlideshowTask(
                        t.resource.map((r) => {
                            return new Mediafile(r.id, 'image', r.folder)
                        }),
                        schedule,
                        t.delay
                    )
                case 'stream':
                    return new StreamTask(
                        t.resource,
                        schedule
                    )
                case 'web':
                    return new WebTask(
                        t.resource,
                        schedule
                    )
                case 'sleep':
                    return new SleepTask(schedule);
                default:
                    throw Error('Unknown task type');
            }
        })
        this.tasks = parsedTasks;
    }
}
