<template>
<div id="masterStep1" class="ds-dialog-fog" @click="close" v-if="visible">
    <WarningModal :isActive="openWarning" :text="warning_text"/>
		<div class="ds-dialog ds-dialog-master" @click.stop>
			<div class="dialog-header">{{currentStep === 0 ? 'Шаг 1 – выберите плееры' 
        : currentStep === 1 ? 'Шаг 2 – выберите плейлист для плееров' 
        : 'Шаг 3 – Укажите дополнительные опции'}}
        <img @click="close" src="@/assets/imgs/playlist/delete.svg"/>
      </div>
			<div class="dialog-body" style="display: flex; flex-direction: row">
				<div class="master-steps">
					<div :class="{'current-step': currentStep === 0}">Выбор плееров</div>
					<div :class="{'current-step': currentStep === 1}">Выбор плейлиста</div>
          <div :class="{'current-step': currentStep === 2}">Дополнительно</div>
				</div>
				<div v-if="currentStep !== 2" style="display: flex; flex-direction: row; flex-grow: 1;">
          <!-- step 1 -->
          <div v-if="currentStep === 0" class="master-body">
            <div style="overflow-y: auto; flex-grow: 1;">
            <div @click="togglePlayer(item)" v-for="item in onlinePlayers" :key="item.id" class="list-item">
              <div class="list-item-checkbox">
                <input type="checkbox" v-model="selectedPlayers" :value="item.id">
              </div>
              <div class="list-item-icon" style="cursor: pointer;">
              </div>
              <div class="list-item-caption" style="cursor: pointer;">{{ item.name }}</div>
              <div class="list-item-buttons"></div>
            </div>
            </div>
            <div style="padding: 10px 5px 5px; margin-top: auto" v-if="selectedPlayers.size > 1">
                <div>
                    <label><input type='checkbox' v-model="group.enabled">Создать группу из выбранных устройств</label>
                </div>
                <div style="margin-top: 5px;" v-if="group.enabled">
                    <label>Название группы</label>
                    <input type='text' v-model="group.name" />
                </div>
            </div>
          </div>
          <!-- Step 2 -->
          <div v-if="currentStep === 1" class="master-body">
          <div style="overflow: auto; flex-grow: 1;" id="masterSchedulesList">
            <div v-for="schedule in schedules" :class="{'active': selectedSchedule == schedule}" class="list-item" @click="selectSchedule(schedule)" :key="schedule.id">
              <div class="list-item-icon" style="cursor: pointer;">
              </div>
              <div class="list-item-caption" style="cursor: pointer;">{{ schedule.name }}</div>
              <div class="list-item-buttons"></div>
            </div>
          </div>
          </div>
				</div>
        <!-- Step 3 -->
        <div v-if="currentStep === 2" class="master-body">
          <div style="padding: 10px 5px 5px">
            <div>
              <label><input v-model="headline.enabled" type="checkbox"> Установить бегущую строку</label>
            </div>
            <div v-if="headline.enabled" style="margin-top: 5px;">
              <label>Текст бегущей строки</label>
              <input class="ticker" v-model="headline.text" type="text">
            </div>
          </div>
          <div style="padding: 10px 5px 5px">
            <div>
              <label><input v-model="sleep.enabled" type="checkbox"> Добавить спящий режим</label>
            </div>
            <div style="margin-top: 5px;" v-if="sleep.enabled">
              <table>
                <tbody><tr>
                  <td>Время спящего режима c </td>
                  <td><input v-model="sleep.start" type="time"></td>
                  <td> по </td>
                  <td><input v-model="sleep.end" type="time"></td>
                </tr>
              </tbody></table>
              <div style="background: #FD8; border: 1px solid #CA4; padding: 10px;">Задача перехода в спящий режим будет добавлена в выбраный плейлист</div>
            </div>
          </div>
				</div>
			</div>
			<div class="dialog-footer">
				<button class="btn" type="button" v-if="currentStep === 0" @click="close">Отмена</button>
				<button class="btn" type="button" v-if="currentStep > 0" @click="currentStep -= 1">Назад</button>
				<button @click="nextStep" v-if="currentStep < 2" class="btn btn-primary" type="button">Далее</button>
				<button @click="saveMaster" v-if="currentStep === 2" class="btn btn-primary" type="button">Готово</button>
			</div>
		</div>
	</div>
</template>

<script>
import StatefulModal from '@/mixins/StatefulModal'
import WarningModal from '../WarningModal.vue'

export default {
  components: { WarningModal },
  inject: ['schedulesAPI', 'playersAPI', 'groupsAPI', 'messagePopup'],
  mixins: [StatefulModal],
  name: 'PlayerSetupWizard',
  data: () => ({
    selectedSchedule: null,
    selectedPlayers: new Set(),
    headline: {
      enabled: false,
      text: ''
    },
    group: {
      enabled: false,
      name: ''
    },
    sleep: {
      enabled: false,
      start: '00:00',
      end: '00:00'
    },
		schedules: [],
    currentStep: 0,

    openWarning: false,
    warning_text: "",
  }),
  props: {
    devices: Array
  },
  mounted() {
    
  },
  watch: {
    visible: function(isOpen) {
      if(isOpen){
        this.selectedPlayers = new Set();
        this.selectedSchedule = null;
        this.headline.enabled = false;
        this.headline.text = '';
        this.group.enabled = false;
        this.group.name = '';
        this.sleep.enabled = false;
        this.sleep.start = '00:00';
        this.sleep.end = '00:00';
        this.fetchSchedules();
      }
    }
  },
  methods: {
    async fetchSchedules() {
      try {
          this.schedules = await this.schedulesAPI.fetch();
      } catch(e) {
          if(e instanceof APIError){
              this.messagePopup.show('Ошибка сервера: ' + e.response.reason);
          }
      }
    },
    togglePlayer(item) {
      if(this.selectedPlayers.has(item.id))
        this.selectedPlayers.delete(item.id)
      else
        this.selectedPlayers.add(item.id)
    },
    selectSchedule(item){ 
      this.selectedSchedule = item;
    },
    async saveMaster() {
      try {
        if(this.sleep.enabled)
          await this.schedulesAPI.addSleepTask(this.selectedSchedule.id, this.sleep.start, this.sleep.end)
        await this.playersAPI.assignMany(Array.from(this.selectedPlayers), this.selectedSchedule.id);
        if(this.group.enabled)
          await this.groupsAPI.createWithPlayers(this.group.name, Array.from(this.selectedPlayers));
        if(this.headline.enabled)
          await this.playersAPI.updateHeadline(Array.from(this.selectedPlayers), this.headline.text);
      } catch(e) {
        this.messagePopup.show('Не удалось применить настройки');
      } finally {
        this.$emit('done');
        this.close();
      }
    },
    nextStep() {
      if(this.currentStep === 0) {
        if(this.selectedPlayers.size < 1) {
          this.messagePopup.show("Необходимо выбрать хотя бы одно устройство");
          return
        }
        if(this.group.enabled && this.group.name.trim() == '') {
          this.messagePopup.show("Введите название группы");
          return
        }
        this.currentStep++;
      } else if(this.currentStep === 1) {
        if(this.selectedSchedule == null) {
          this.messagePopup.show("Необходимо выбрать плейлист");
          return
        }
        this.currentStep++;
      }
    },
  },
  computed: {
		onlinePlayers() {
			return this.devices.filter(x => {
				return x.status != 'offline';
			})
		}
  }
}
</script>
<style scoped>
th, td {
  padding: 10px;
  vertical-align: middle;
}
.dialog-body input[type=text], .dialog-body input[type=time], .dialog-body input .dialog-body select, .ticker {
  display: block;
  padding: 6px;
  margin: 6px 0;
  width: 100%;
  box-sizing: border-box;
  background: #FFF;
  border: 1px solid #909090;
}
input[type=checkbox] {
  margin-right: 10px;
}
#masterSchedulesList .list-item .list-item-icon {
  background-image: url(@/assets/imgs/playlist/playlist.svg);
  background-size: 96px 46px;
  height: 46px;
}
#masterSchedulesList .list-item.active {
  background-color: #ACE;
}
.list-item-icon {
  background-image: url(@/assets/imgs/playing/monitor.svg);
  height: 56px;
  width: 96px;
  background-repeat: no-repeat;
  background-size: 96px 56px;
}  
.ds-dialog-fog {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}
.ds-dialog {
  max-width: 960px;
  width: 100%;
  height: 90%;
  max-height: 90%;
  overflow: hidden;
  background: #EFEFEF;
  border: 1px solid #080808;
  box-sizing: border-box;
  box-shadow: 0 0 8px rgb(0 0 0 / 30%);
  display: flex;
  flex-direction: column;
}
.dialog-header {
  background: #555;
  color: #FFF;
  margin: 0;
  padding: 10px;
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.dialog-header img {
  filter: invert(1);
  cursor: pointer;
}
.ds-dialog > .dialog-body {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.dialog-footer {
  margin: 0 5px;
  padding: 10px;
  text-align: right;
  border-top: 1px solid #CCC;
}
.master-steps {
  width: 200px;
}
.master-body {
  flex-grow: 1;
  margin-left: 10px;
  border-left: 1px solid #AAA;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.master-steps > .current-step {
  font-weight: bold;
  color: #000;
  padding: 10px;
}
.master-steps > div {
  padding: 10px;
  color: #999;
}
.list-item {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #DDD;
}
.list-item-caption {
  flex-grow: 1;
  padding: 10px;
  min-height: 56px;
  font-size: 14px;
}
.btn.btn-primary {
  background: #6c8;
  margin-left: 5px;
}
.btn {
  font: 400 10pt "Golos Text", "Open Sans", "Segoe UI", "Liberation Sans", sans-serif;
  background: #888;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 5px 10px;
  vertical-align: bottom;
  min-width: 120px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
</style>
