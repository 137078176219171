<template>
  <div class="modal_window" @click="close" v-if="visible">
    <div class="modal_container">
      <div class="modal" @click.stop>
        <div class="head">
          <span>{{ title }}</span>
          <img @click="close" src="@/assets/imgs/playlist/delete.svg" />
        </div>
        <div class="content">
          <div class="edit_task">
            <SetTaskSchedule
              :task="task"
              :allowOneShot="false"
              :disableDefaultTask="disableDefaultTask" />
            
            <div v-if="isWeb" class="web_block">
              <span>Пример: http://site.ru/your-page.htm</span>
              <input @keyup.enter="saveModal" placeholder="Введите url" v-model="task.url"/>
            </div>

            <div v-if="isStream" class="stream_block">
              <input @keyup.enter="saveModal" placeholder="Введите url" v-model="task.url"/>
            </div>
          </div>
        </div>
        <div class="footer">
          <button @click="submit" class="btn">Ок</button>
          <button @click="close" class="btn">Закрыть</button>
        </div>
      </div>
    </div>
    <ConfirmModal
      ref="confirmEmptyURLModal"
      title="Предупреждение"
      text="Задача содержит пустой URL. Вы действительно хотите её сохранить?"
      okText="Сохранить"
      @confirm="submitAnyway"
      />
  </div>
</template>

<script>
import StatefulModalMixin from "@/mixins/StatefulModal";
import { Task, WebTask, StreamTask } from "@/api/ScheduleModel";
import SetTaskSchedule from "./SetTaskSchedule.vue";
import ViewWithModalsMixin from "@/mixins/ViewWithModalsMixin";
import ConfirmModal from "../ConfirmModal.vue";

export default {
  components: {
    SetTaskSchedule,
    ConfirmModal
  },
  mixins: [StatefulModalMixin, ViewWithModalsMixin],
  name: "EditURLTask",
  data: function() {
    return {
      task: {},
    }
  },
  props: {
    title: String,
    disableDefaultTask: Boolean
  },
  watch: {
    state: function(){
      if(this.state === null) return;
      if(('task' in this.state) && (this.state.task instanceof Task)){
        this.task = this.state.task.copy();
      } else {
        console.warn('There is no editable task');
      }
    }
  },
  methods: {

    submit() {
      // TODO: check empty playlist
      if(this.task.url.trim().length < 1){
        this.openModal('confirmEmptyURLModal', null);
      } else {
        this.submitAnyway();
      }
    },
    submitAnyway() {
      this.$emit('update-task', this.task, this.state.index);
      this.close();
    },
  },
  computed: {
    isWeb() {
      return this.task instanceof WebTask;
    },
    isStream() {
      return this.task instanceof StreamTask;
    },
  },
};
</script>
<style scoped>
  .web_block {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #080808;
    padding: 0px 5px;
    font-size: 14px;
  }
  .web_block input {
    width: 100%;
    padding: 5px;
    font-size: 18px;
    margin-top: 5px;
    outline: none;
  }
  .stream_block {
    display: flex;
    width: 100%;
    color: #080808;
    padding: 0px 5px;
    font-size: 14px;
  }
  .stream_block input, .stream_block select {
    width: 50%;
    padding: 5px;
    font-size: 18px;
    margin-top: 5px;
    outline: none;
  }
  .edit_task {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }
  @media (max-width: 960px) {
    .modal_window .modal_container {
      width: 99%;
    }
  }
  .time_container {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  .select_time {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #141414;
    padding: 10px;
    width: 26%;
  }
  .select_time select {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    height: 40px;
  }
  .select_time span {
    font-size: 10pt;
    font-weight: bold;
    padding: 5px;
  }
  .modal_container {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
  }
  .modal_container .modal {
    max-width: 960px;
    width: 100%;
    max-height: 90%;
    overflow: hidden;
    background: #EFEFEF;
    border: 1px solid #080808;
    box-sizing: border-box;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    z-index: 3;
    display: flex;
    flex-direction: column;
  }
  .modal .head {
    background: #555;
    color: #FFF;
    margin: 0;
    padding: 10px;
    font-size: 1.3em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .head img {
    filter: invert(1);
    cursor: pointer;
  }
  .modal .footer {
    width: 100%;
    padding: 10px;
    text-align: right;
    border-top: 1px solid #CCC;
    background-color: #efefef;
  }
  .modal .content {
    width: 100%;
    height: 100%;
  }
  .select_time input, .select_time select {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    height: 40px;
  }

  .footer .btn {
    font: 400 10pt "Open Sans", "Segoe UI", "Liberation Sans", sans-serif;
    background: #888;
    color: #fff;
    border: 0;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 0px 2px;
    vertical-align: bottom;
    min-width: 120px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
  }
  .btn:hover {
    background: #797979;
  }
</style>
