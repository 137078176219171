<template>
  <div class="modal_window" @click="close" v-if="visible">
    <div class="modal_container">
      <div class="modal" @click.stop>
        <div class="head">
          <span>{{ title }}</span>
          <img @click="close" src="@/assets/imgs/playlist/delete.svg" />
        </div>
        <div class="content">
          <div class="edit_task">
            <SetTaskSchedule
              :task="task"
              :allowOneShot="!isDocuments"
              :disableDefaultTask="disableDefaultTask" />

            <div v-if="isSlideshow" class="slide_delay">
              <b>Интервал показа страниц/слайдов</b>
              <input type="number" v-model="task.delay" />
            </div>

            <div class="container_slides">
              <div class="taskFilesBox" v-if="isPlaylist">
                <div
                  class="selected_slide"
                  @drop.stop="onDrop($event, playlistItems.length, 'playlist')"
                  @dragover.prevent
                  @dragenter.prevent
                >
                <div
                    class="list-item"
                    @click="playlistFolder = null"
                    v-if="playlistFolder !== null"
                  >
                    <div
                      class="list-item-icon"
                      :style="{
                        'background-image':
                          'url(' +
                          require('@/assets/imgs/stuff/folder-up.svg') +
                          ')',
                      }"
                    ></div>
                    <div class="list-item-caption"><b>Назад</b></div>
                  </div>
                  <div
                    @click="playlistDefaultAction(item, index)"
                    class="list-item"
                    v-for="(item, index) in playlistItems"
                    :key="item.id"
                    draggable="true"
                    @dragstart="startDrag($event, index, 'playlist')"
                    @drop.stop="onDrop($event, index, 'playlist')"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <div
                      class="list-item-icon"
                      :style="{
                        'background-image': 'url(' + item.thumbnail + ')',
                      }"
                    ></div>
                    <div class="list-item-caption">
                      <b>{{ item.name ? item.name : item.id }}</b>
                    </div>
                    <div class="list-item-buttons" v-if="playlistFolder === null">
                      <button
                        @click.stop="task.moveUp(index)"
                        type="button"
                        title="Вверх"
                      >
                        <img src="@/assets/imgs/playlist/up.svg" />
                      </button>
                      <button
                        @click.stop="task.moveDown(index)"
                        type="button"
                        title="Вниз"
                      >
                        <img src="@/assets/imgs/playlist/down.svg" />
                      </button>
                      <button
                        @click.stop="task.removeByIndex(index)"
                        type="button"
                        title="Убрать"
                      >
                        <img src="@/assets/imgs/playlist/remove.svg" />
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  @drop="onDrop($event, files.length, 'files')"
                  @dragover.prevent
                  @dragenter.prevent
                  class="all_slides"
                >
                  <div
                    class="list-item"
                    @click="currentFolder = null"
                    v-if="currentFolder !== null"
                  >
                    <div
                      class="list-item-icon"
                      :style="{
                        'background-image':
                          'url(' +
                          require('@/assets/imgs/stuff/folder-up.svg') +
                          ')',
                      }"
                    ></div>
                    <div class="list-item-caption"><b>Назад</b></div>
                  </div>
                  <div
                    @click="defaultFileItemAction(item)"
                    class="list-item"
                    v-for="(item, index) in filesToAdd"
                    :key="item.id"
                    @dragstart="startDrag($event, item.id, 'files')"
                    draggable="true"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <div
                      class="list-item-icon"
                      :style="{
                        'background-image': 'url(' + item.thumbnail + ')',
                      }"
                    ></div>
                    <div class="list-item-caption">
                      <div>
                        <b>{{ item.name }}</b>
                      </div>
                      <div v-if="!item.folder">Size: {{ item.size }}</div>
                    </div>

                    <div v-if="item.folder" class="list-item-buttons">
                      <button
                        @click.stop="currentFolder = item.id"
                        type="button"
                        title="Открыть"
                      >
                        <img src="@/assets/imgs/playlist/openfolder.svg" />
                      </button>
                      <!-- add folder -->
                      <button
                        @click.stop="includeFolder(item)"
                        type="button"
                        title="Добавить папку"
                      >
                        <img src="@/assets/imgs/playlist/addfolder.svg" />
                      </button>
                    </div>
                    <div v-else class="list-item-buttons">
                      <button
                        @click.stop="includeFile(item)"
                        type="button"
                        title="Добавить"
                      >
                        <img src="@/assets/imgs/playlist/add.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button @click="submit" class="btn">Ок</button>
          <button @click="close" class="btn">Закрыть</button>
        </div>
      </div>
    </div>
    <ConfirmModal
        ref="confirmIncludeModal"
        title="Предупреждение"
        text="Папка не создержит подходящих материалов. Вы действительно хотите добавить: "
        okText="Добавить"
        @confirm="includeFolderAnyway"
      />
    <ConfirmModal
      ref="confirmEmptyPlaylistModal"
      title="Предупреждение"
      text="Задача не содержит материалов для показа. Вы действительно хотите её сохранить?"
      okText="Сохранить"
      @confirm="submitAnyway"
      />
  </div>
</template>

<script>
import { formatBytes } from "@/api/playlist/func";
import ConfirmModal from "@/components/ConfirmModal.vue";
import StatefulModalMixin from "@/mixins/StatefulModal";
import { PlaylistTask, SlideshowTask, Mediafile, Task, DocumentTask } from "@/api/ScheduleModel";
import SetTaskSchedule from "./SetTaskSchedule.vue";
import ViewWithModalsMixin from "@/mixins/ViewWithModalsMixin";

export default {
  components: {
    ConfirmModal,
    SetTaskSchedule,
  },
  mixins: [StatefulModalMixin, ViewWithModalsMixin],
  name: "EditPlaylistTask",
  data: function(instance) {
    return {
      currentFolder: null,
      playlistFolder: null,
      task: {},
    }
  },
  props: {
    title: String,
    files: Array,
    disableDefaultTask: Boolean
  },
  mounted() {
  },
  watch: {
    visible: function(v) {
      if(v){
        this.currentFolder = null;
        this.playlistFolder = null;
      }
    },
    state: function(){
      if(this.state === null) return;
      if(('task' in this.state) && (this.state.task instanceof Task)){
        this.task = this.state.task.copy();
      } else {
        console.warn('There is no editable task');
      }
    }
  },
  methods: {
    startDrag(evt, itemReference, origin) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      // NOTE: itemReference is index for playlist and file_id for files
      evt.dataTransfer.setData("itemReference", itemReference);
      evt.dataTransfer.setData("itemOrigin", origin);
    },
    onDrop(evt, targetIndex, target) {
      const itemReference = evt.dataTransfer.getData("itemReference");
      const origin = evt.dataTransfer.getData("itemOrigin");
      if(this.playlistFolder != null) return;
      if((origin == 'files') && (target == 'files')) {
        return;
      } else if((origin == 'playlist') && (target == 'files')) {
        this.task.removeByIndex(itemReference);
      } else if((origin == 'files') && (target == 'playlist')) {
        let file = this.filenames[itemReference];
        if(file.isFolder) {
          this.task.insertBeforeIndex(new Mediafile(file.id, this.task.acceptedType, true), targetIndex);
        } else {
          this.task.insertBeforeIndex(new Mediafile(file.id, file.filetype, false), targetIndex);
        }
        evt.preventDefault();
      } else if((origin == 'playlist') && (target == 'playlist')) {
        this.task.move(itemReference, targetIndex);
      }
    },

    submit() {
      // TODO: check empty playlist
      if(this.task.calculateSize(this.files) < 1){
        this.openModal('confirmEmptyPlaylistModal', null);
      } else {
        this.submitAnyway();
      }
    },
    submitAnyway() {
      this.$emit('update-task', this.task, this.state.index);
      this.close();
    },
    folderHasPlayableItems(folderId) {
      return (
        this.files.filter((f) => {
          return f.folder == folderId && f.filetype == this.task.acceptedType;
        }).length > 0
      );
    },
    playlistDefaultAction(item, index) {
      if(item.folder){
        this.playlistFolder = item.id;
      }
    },
    defaultFileItemAction(item) {
      if(item.folder) {
        this.currentFolder = item.id;
      } else {
        this.includeFile(item);
      }
    },
    includeFile(item) {
      this.task.add(new Mediafile(item.id, item.type, false));
    },
    includeFolder(item) {
      // TODO: calculate items
      if (this.folderHasPlayableItems(item.id)) {
        this.includeFolderAnyway(item);
      } else {
        this.openModal("confirmIncludeModal", item);
      }
    },
    includeFolderAnyway(item) {
      this.task.add(new Mediafile(item.id, item.type, true));
    },
  },
  computed: {
    filenames() {
      let names = {};
      this.files.forEach((f) => {
        names[f.id] = f;
      });
      return names;
    },
    filesToAdd() {
      return this.files
        .filter((f) => {
          return (
            f.folder == this.currentFolder &&
            (f.filetype == this.task.acceptedType || f.isFolder)
          );
        })
        .map((f) => {
          let thumbnailUrl = "";
          if (f.isFolder) {
            thumbnailUrl = require("@/assets/imgs/folder.svg");
          } else if (f.filetype == "document") {
            thumbnailUrl = require("@/assets/imgs/stuff/document.png");
          } else {
            thumbnailUrl = "/thumb/" + f.id + ".jpg";
          }
          return {
            id: f.id,
            name: f.name,
            type: f.isFolder ? this.task.acceptedType : f.filetype,
            folder: f.isFolder,
            size: f.isFolder ? null : formatBytes(f.size),
            thumbnail: thumbnailUrl,
          };
        });
    },
    playlistItems() {
      if (!this.isPlaylist) return [];
      if(this.playlistFolder != null) {
        return this.files
        .filter((f) => {
          return (f.folder == this.playlistFolder) && (f.filetype == this.task.acceptedType);
        })
        .map((f) => {
          let thumbnailUrl = "";
          if (f.isFolder) {
            thumbnailUrl = require("@/assets/imgs/folder.svg");
          } else if (f.filetype == "document") {
            thumbnailUrl = require("@/assets/imgs/stuff/document.png");
          } else {
            thumbnailUrl = "/thumb/" + f.id + ".jpg";
          }
          return {
            id: f.id,
            index: 0,
            name: f.name,
            type: f.filetype,
            size: f.isFolder ? null : formatBytes(f.size),
            thumbnail: thumbnailUrl,
          };
        });
      }
      return this.task.playlist
        .map((item, index) => {
          let thumbnailUrl = "";
          if (item.isFolder) {
            thumbnailUrl = require("@/assets/imgs/folder.svg");
          } else if (item.type == "document") {
            thumbnailUrl = require("@/assets/imgs/stuff/document.png");
          } else {
            thumbnailUrl = "/thumb/" + item.id + ".jpg";
          }
          return {
            id: item.id,
            index: index,
            name: this.filenames[item.id].name,
            folder: item.isFolder,
            size: item.isFolder
              ? null
              : formatBytes(this.filenames[item.id].size),
            thumbnail: thumbnailUrl,
          };
        })
        .filter((item) => {
          return item.id in this.filenames;
        });
    },
    isPlaylist() {
      return this.task instanceof PlaylistTask;
    },
    isSlideshow() {
      return this.task instanceof SlideshowTask;
    },
    isDocuments() {
      return this.task instanceof DocumentTask;
    },
    listSlides() {
      return [...new Set(this.slides)];
    },
  },
};
</script>
<style scoped>
@media (max-height: 905px) {
  .selected_slide,
  .all_slides {
    height: 100%;
  }
}
@media (max-width: 1190px) {
  .modal_window .modal_container {
    width: 99%;
  }
  .modal_container .modal {
    width: 100%;
  }
}
@media (max-width: 414px) {
  .taskFilesBox {
    flex-direction: column;
  }
  .modal_container .modal .footer {
    padding-right: 15px;
  }
  /* 1600 761 */

  .taskFilesBox .all_slides {
    margin-top: 10px;
  }
  .taskFilesBox .selected_slide {
    min-height: 128px;
  }
  .select_time input,
  .select_time select {
    min-width: 165px;
  }
  .edit_task .time_container {
    flex-direction: column;
    align-items: center;
  }
  .time_container .select_time {
    padding: 5px 0;
    width: 90%;
  }
  .time_container .select_time select {
    width: 50%;
  }
}
.edit_task {
  width: 100%;
  height: 84%;
  position: relative;
}
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.slide_delay {
  padding: 5px 20px;
  color: #080808;
  font-size: 14px;
}
.slide_delay b {
  margin-right: 10px;
}
.list-item .slide_info {
  display: flex;
  flex-direction: column;
  color: #080808;
  font-size: 14px;
  justify-content: flex-start;
  margin: auto 0;
  word-break: break-word;
  max-width: 165px;
}
.prewiew_img {
  display: flex;
  justify-content: center;
  width: 130px;
}
.prewiew_img img {
  height: 56px;
  align-self: stretch;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 48px;
  margin-right: 10px;
}
.selected_slide,
.all_slides {
  flex-grow: 1;
  flex-basis: 50%;
  margin: 0 5px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #aaa;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.container_slides {
  height: 100%;
  overflow-y: auto;
}
.taskFilesBox {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.modal_container {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}
.modal_container .modal {
  max-width: 960px;
  width: 100%;
  max-height: 90%;
  height: 86%;
  background: #efefef;
  border: 1px solid #080808;
  box-sizing: border-box;
  box-shadow: 0 0 8px rgb(0 0 0 / 30%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  position: relative;
}
.modal .head {
  background: #555;
  color: #fff;
  margin: 0;
  padding: 10px;
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.head img {
  filter: invert(1);
  cursor: pointer;
}
.modal .footer {
  width: 100%;
  padding: 10px;
  text-align: right;
  border-top: 1px solid #ccc;
  background-color: #efefef;
  z-index: 2;
}
.modal .content {
  width: 100%;
  height: 90%;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
}

.footer .btn {
  font: 400 10pt "Open Sans", "Segoe UI", "Liberation Sans", sans-serif;
  background: #888;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 0px 2px;
  vertical-align: bottom;
  min-width: 120px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: 0.3s all;
}
.btn:hover {
  background: rgb(121, 121, 121);
}
.list-item-buttons {
  /* display: flex; */
}

</style>
